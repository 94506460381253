import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterModule } from '../footer';
import { NavbarModule } from '../navbar';
import { FrameComponent } from './frame.component';

@NgModule({
  declarations: [FrameComponent],
  imports: [CommonModule, NavbarModule, FlexLayoutModule, FooterModule],
  exports: [FrameComponent],
})
export class FrameModule {}
