import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PipeUtils } from '@tdb/utils';
import { SourceDataState } from './source-data.state';
import { SourceDataStore } from './source-data.store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SourceDataQuery extends Query<SourceDataState> {
  constructor(store: SourceDataStore) {
    super(store);
  }

  selectHasDataModules(): Observable<boolean> {
    return this.select((state) => state.dataModules).pipe(
      map((dataModules) => dataModules.length > 0),
    );
  }

  selectDataModules(): Observable<string[]> {
    return this.select((state) => state.dataModules).pipe(
      PipeUtils.filterEmptyList,
    );
  }
  selectDataTypes(): Observable<string[]> {
    return this.select((state) => state.dataTypes).pipe(
      PipeUtils.filterEmptyList,
    );
  }
  selectDataSource(): Observable<string> {
    return this.select((state) => state.dataSource).pipe(
      PipeUtils.filterUndefined,
    );
  }

  selectUploadingData(): Observable<boolean> {
    return this.select((state) => state.uploadingSourceData);
  }
  selectLoadingModules(): Observable<boolean> {
    return this.select((state) => state.loadingDataModules);
  }
  selectLoadingTypes(): Observable<boolean> {
    return this.select((state) =>
      [state.loadingDataModules, state.loadingDataType].some(Boolean),
    );
  }
  selectLoadingSource(): Observable<boolean> {
    return this.select((state) => this.getDisableSourceState(state));
  }

  getLoading(): boolean {
    return this.getLoadingState(this.getValue());
  }
  selectLoading(): Observable<boolean> {
    return this.select((state) => this.getLoadingState(state));
  }

  private getLoadingState(state: SourceDataState): boolean {
    return [
      state.loadingDataModules,
      state.loadingDataType,
      state.loadingDataSource,
      state.uploadingSourceData,
    ].some(Boolean);
  }
  private getDisableSourceState(state: SourceDataState): boolean {
    return [
      state.loadingDataModules,
      state.loadingDataType,
      state.loadingDataSource,
    ].some(Boolean);
  }
}
