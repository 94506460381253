import fill from 'fill-range';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { Coerce, Moment, TimeUtils } from '@tdb/utils';

@Component({
  selector: 'tdb-multi-inputs',
  templateUrl: './multi-inputs.component.html',
  styleUrls: ['./multi-inputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiInputsComponent),
      multi: true,
    },
    // GeneralUtils.getCVAProvider(MultiInputsComponent),
  ],
})
export class MultiInputsComponent extends ControlValueAccessor<number[]> {
  private readonly controls: FormControl<number>[] = [];
  private values: number[] = [];
  private lengthState = new BehaviorSubject<number>(0);
  @Input() set length(length: number) {
    this.lengthState.next(length);
  }
  readonly itemsInLength$ = this.selectHours();
  readonly moments$ = this.selectMoments();

  constructor() {
    super();
    this.takeFirstValidHoursForValuesInit();
  }

  writeValue(value: number[]): void {
    this.patchValues(this.reconstructValuesWithIncoming(value), this.controls);
    this.values = this.reconstructValuesWithIncoming(value);
  }

  getValues(): number[] {
    return this.values;
  }

  onValueChange(index: number, value: number): void {
    this.values[index] = value;
    this.coercedOnChange(this.values);
  }

  onPeyst(content: string): void {
    this.writeValue(this.coerceToNumbers(this.splitContentByNewLine(content)));
  }

  private reconstructValuesWithIncoming(incoming: number[]): number[] {
    return [...incoming, ...this.values.slice(incoming.length)];
  }

  private splitContentByNewLine(content: string): string[] {
    return content.split('\n');
  }

  private coerceToNumbers(strings: string[]): number[] {
    return strings.map((str) => Coerce.number(str));
  }

  private get coercedOnChange(): (value: number[]) => void {
    return Coerce.fn(this.onChange);
  }

  private patchValues(values: number[], ctrls: FormControl<number>[]): void {
    ctrls.forEach((ctrl, idx) => ctrl.setValue(values[idx]));
  }

  private takeFirstValidHoursForValuesInit(): void {
    this.selectHours()
      .pipe(take(1))
      .subscribe((hours) => {
        this.values = new Array(hours.length).fill(0) as number[];
      });
  }

  private selectHours(): Observable<number[]> {
    return this.lengthState.pipe(
      filter((length) => length > 0),
      map((length) => fill(0, length - 1)),
    );
  }

  private selectMoments(): Observable<ReferencedMoment[]> {
    return this.selectHours().pipe(map((hours) => this.hoursToMoments(hours)));
  }

  private hoursToMoments(hours: number[]): ReferencedMoment[] {
    return hours.map((hour) => this.hourToMoment(hour));
  }

  private hourToMoment(hour: number): ReferencedMoment {
    const control = new FormControl<number>(0);
    this.controls.push(control);
    return {
      ...TimeUtils.hoursToMoment(hour),
      referenceHour: hour,
      control,
    };
  }
}

export interface ReferencedMoment extends Moment {
  readonly referenceHour: number;
  readonly control: FormControl<number>;
}
