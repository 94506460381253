export enum ChartColor {
  RED = '#FF0000',
  MAROON = '#800000',
  YELLOW = '#FFFF00',
  OLIVE = '#808000',
  LIME = '#00FF00',
  GREEN = '#008000',
  AQUA = '#00FFFF',
  TEAL = '#008080',
  BLUE = '#0000FF',
  NAVY = '#000080',
  FUCHSIA = '#FF00FF',
  PURPLE = '#800080',
}
