import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavBarLink } from '../navbar';

@Component({
  selector: 'tdb-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent {
  @Input() links: NavBarLink[] = [];
  @Input() loggedIn = false;

  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() home = new EventEmitter();
  @Output() goto = new EventEmitter();
}
