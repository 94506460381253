import { NOT_APPLICABLE } from '@tdb/app/app.tokens';

export interface SourceDataState {
  readonly loadingDataModules: boolean;
  readonly loadingDataType: boolean;
  readonly loadingDataSource: boolean;
  readonly uploadingSourceData: boolean;
  readonly dataModules: string[];
  readonly dataTypes: string[];
  readonly dataSource: string;
}

export interface UploadSourceDataRequest {
  readonly file: File | undefined;
  readonly nameOfClient: string;
  readonly source: string;
  readonly module: string;
  readonly type: string;
}

export interface UploadSourceDataResponse {
  readonly errorMessage: string;
  readonly signed_url: string;
}

export interface DownloadSourceDataResponse {
  readonly filename: string;
  readonly signed_url: string;
}

export interface ModuleConfigResponse {
  readonly modules: string[];
}

export interface FilterConfigResponse {
  readonly types: string[];
}

export interface SourceInfo {
  readonly source: string;
  readonly lastUpdated: string;
}

export function getInitialValues(): SourceDataState {
  return {
    loadingDataModules: false,
    loadingDataType: false,
    loadingDataSource: false,
    uploadingSourceData: false,
    dataModules: [],
    dataTypes: [],
    dataSource: NOT_APPLICABLE,
  };
}
