export interface AboutState {
  readonly uploadingInfo: boolean;
  readonly loadingInfo: boolean;
  readonly infoContent: string;
}

export interface UploadGlobalInfoRequest {
  readonly file: File | undefined;
}

export interface UploadGlobalInfoResponse {
  readonly filename: string;
  readonly signed_url: string;
}

export interface DownloadGlobalInfoResponse {
  readonly filename: string;
  readonly signed_url: string;
}
