import { FilterCriteria } from '@tdb/app/state/filtration';

export interface Price {
  readonly commodity: string;
  readonly geography: string;
  readonly scenario: string;
  readonly unit: string;
  readonly values: Record<number, number>;
}

export type AggregatablePriceKey = keyof Omit<Price, 'unit' | 'values'>;

export interface PriceState {
  readonly aggregated: Price[];
  readonly prices: Price[];
  readonly years: number[];
  readonly filtering: boolean;
  readonly factors: Factors;
  readonly nature: PriceNature;
  readonly weighting: boolean;
  readonly activeFilter: FilterCriteria;
  readonly refreshing: boolean;
  readonly factorize: boolean;
  readonly loadingSource: boolean;
  readonly activeCommodity: Commodity;
  readonly averageOn?: AggregatablePriceKey[];
  readonly source: PriceSource;
}

export enum Commodity {
  ELECTRICITY = 'Electricity',
  NON_ELECTRICITY = 'Non-electricity',
}

export const DEFAULT_COMMODITY: Commodity = Commodity.ELECTRICITY;

export interface PriceSource {
  readonly source: string;
  readonly lastUpdated: string;
}

export interface GetPricesResponse {
  readonly data: Price[];
  readonly startYear: number;
  readonly endYear: number;
  readonly type: PriceNature;
}

export interface Factors {
  readonly transpoAndDistrib: number;
  readonly margin: number;
  readonly taxes: number;
  readonly indexation: number;
}

export enum PriceNature {
  DEFAULT = 'default',
  WEIGHTED = 'custom',
  PARTIAL = 'partial',
}

export type UpdateWeightsResponse = unknown;

export interface GetSourceInfoResponse extends PriceSource {}

function initializeFactors(): Factors {
  return {
    transpoAndDistrib: 0,
    margin: 0,
    taxes: 0,
    indexation: 0,
  };
}

export function initializeState(): PriceState {
  return {
    aggregated: [],
    prices: [],
    years: [],
    filtering: false,
    factors: initializeFactors(),
    nature: PriceNature.DEFAULT,
    weighting: false,
    activeFilter: {},
    refreshing: false,
    factorize: true,
    loadingSource: false,
    activeCommodity: DEFAULT_COMMODITY,
    source: { source: '', lastUpdated: '' },
  };
}

export interface WeightsInfo {
  readonly commodity: string;
  readonly values: number[];
  readonly firstDay: number;
}
