import { Observable, pipe } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';

function skipAndTake1<T>(times: number): ($: Observable<T>) => Observable<T> {
  return pipe(skip(times), take(1));
}

function filterEmptyList<T>($: Observable<T[]>): Observable<T[]> {
  return $.pipe(filter((data: T[]) => [!!data, data.length].every(Boolean)));
}

function filterUndefined<T>($: Observable<T>): Observable<T> {
  return $.pipe(filter((data) => !!data));
}

export const PipeUtils = {
  skipAndTake1,
  filterEmptyList,
  filterUndefined,
};
