import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataType } from '@tdb/app/app.tokens';
import { zoomInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'tdb-data-types',
  templateUrl: './data-types.component.html',
  styleUrls: ['./data-types.component.scss'],
  animations: [
    zoomInOnEnterAnimation({ anchor: 'first', duration: 300 }),
    zoomInOnEnterAnimation({ anchor: 'second', delay: 50, duration: 300 }),
    zoomInOnEnterAnimation({ anchor: 'third', delay: 100, duration: 300 }),
    zoomInOnEnterAnimation({ anchor: 'fourth', delay: 150, duration: 300 }),
    zoomInOnEnterAnimation({ anchor: 'fifth', delay: 200, duration: 300 }),
    zoomInOnEnterAnimation({ anchor: 'sixth', delay: 250, duration: 300 }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypesComponent {
  constructor(private router: Router) {}

  onTechClick(): void {
    this.navigateTo(DataType.TECHNOLOGIES);
  }

  onPricesClick(): void {
    this.navigateTo(DataType.PRICES);
  }

  onFactorsClick(): void {
    this.navigateTo(DataType.FACTORS);
  }

  onSourcingClick(): void {
    this.navigateTo(DataType.SOURCING);
  }

  onWeatherClick(): void {
    this.navigateTo(DataType.RENEWABLE);
  }

  onProfileClick(): void {
    this.navigateTo(DataType.PROFILES);
  }

  private navigateTo(where: string): void {
    void this.router.navigate([where]);
  }
}
