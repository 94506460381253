import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@tdb/core/auth';
import { Coerce } from '@tdb/utils';
import {
  TDB_OKTA_GROUP,
  TDB_OKTA_GROUP_TYPE,
  getUploadPermissionMap,
} from '@tdb/services/permission-checker';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PermissionCheckerService {
  readonly adminAccess$: Observable<boolean>;

  constructor(private readonly authService: AuthService) {
    this.adminAccess$ = this.checkUploadPermission([TDB_OKTA_GROUP.ADMIN]);
  }

  checkUploadPermission(accessors: TDB_OKTA_GROUP_TYPE[]): Observable<boolean> {
    return this.getUserPermissions().pipe(
      map((permissions) => this.hasSomePermission(permissions, accessors)),
      startWith(false),
    );
  }

  getPermittedList(list: string[]): Observable<string[]> {
    return this.getUserPermissions().pipe(
      map((permissions) => this.filterPermittedList(permissions, list)),
    );
  }

  private hasSomePermission(
    permissions: string,
    accessors: TDB_OKTA_GROUP_TYPE[],
  ): boolean {
    return accessors.some((accessor) => permissions.indexOf(accessor) !== -1);
  }

  private filterPermittedList(permissions: string, list: string[]): string[] {
    return list.filter((item) =>
      this.hasSomePermission(permissions, this.getUploadPermission(item)),
    );
  }

  private getUserPermissions(): Observable<string> {
    return this.authService.user$.pipe(
      untilDestroyed(this),
      map((user) => Coerce.obj(user.attributes)),
      map((attr) => Coerce.string(attr['custom:groupName'], '[]')),
    );
  }

  private getUploadPermission(module: string): TDB_OKTA_GROUP_TYPE[] {
    const normal = this.normalizeValue(module);
    const permissions = Coerce.array(getUploadPermissionMap()[normal]);
    permissions.push(TDB_OKTA_GROUP.ADMIN);
    return permissions;
  }

  private normalizeValue(value: string): string {
    return value
      .replace(/[^a-zA-Z0-9\-\s]/g, '')
      .replace(/[\-\s]+/g, '_')
      .toLowerCase();
  }
}
