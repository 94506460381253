<ul tdbPaste (peyst)="onPeyst($event)">
  <li *ngFor="let moment of moments$ | ngrxPush; let i = index">
    <span
      [attr.data-test]="'month-' + moment.referenceHour"
      class="input-month margin-right"
    >
      {{ moment.month }}
      {{ moment.day }}
    </span>
    <span
      [attr.data-test]="'hour-' + moment.referenceHour"
      class="input-hour margin-right"
    >
      {{ moment.hour }}:00
    </span>
    <input
      class="input-value"
      type="number"
      [attr.data-test]="'value-' + moment.referenceHour"
      [formControl]="moment.control"
      (change)="onValueChange(i, moment.control.value)"
    />
  </li>
</ul>
