<div
  *ngIf="loading; else loaded"
  fxLayout="row"
  fxLayoutGap="16px"
  fxLayoutAlign="start center"
>
  <ngx-skeleton-loader appearance="circle"></ngx-skeleton-loader>
  <div fxLayout="column" fxFlex>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <ngx-skeleton-loader fxFlex="60"></ngx-skeleton-loader>
      <ngx-skeleton-loader fxFlex="20"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row">
      <ngx-skeleton-loader fxFlex></ngx-skeleton-loader>
    </div>
  </div>
</div>

<ng-template #loaded>
  <mtx-loader *ngrxLet="notif$ as notif" [loading]="notif.processing">
    <div
      fxLayout="row"
      fxLayoutGap="24px"
      fxLayoutAlign="start start"
      [class.already-read]="notif.processing || notif.read"
    >
      <div class="notif-icon">
        <mat-icon data-test="icon">{{ typeIconBag[notif.type] }}</mat-icon>
      </div>
      <div fxLayout="column" fxLayoutGap="2px" fxFlex>
        <span data-test="subject" class="notif-subject">{{
          notif.subject
        }}</span>
        <span data-test="body" class="notif-body">{{ notif.body }}</span>
      </div>
      <span data-test="date" class="notif-date">
        {{ notif.date | date: "MMM dd, HH:mm" }}
      </span>
    </div>
  </mtx-loader>
</ng-template>
