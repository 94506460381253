<div fxLayout="column" [formGroup]="ticketForm">
  <h2 data-test="title">{{ "dialog.titles.createTicket" | transloco }}</h2>
  <ng-select
    [clearable]="false"
    data-test="selectType"
    formControlName="type"
    class="support-ticket-type"
  >
    <ng-option *ngFor="let type of ticketTypes" [value]="type" data-test="type">
      <img class="type-icon" [src]="'/assets/icons/' + type + '.svg'" />
      {{ "support.issueTypes." + type | transloco }}
    </ng-option>
  </ng-select>
  <mat-form-field appearance="outline">
    <mat-label>{{ "labels.summary" | transloco }}</mat-label>
    <input required matInput data-test="summary" formControlName="summary" />
    <mat-error data-test="summaryError">
      {{ "errors.fieldIsRequired" | transloco }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ "labels.description" | transloco }}</mat-label>
    <textarea
      rows="5"
      required
      matInput
      data-test="description"
      formControlName="description"
    ></textarea>
    <mat-error data-test="descriptionError">
      {{ "errors.fieldIsRequired" | transloco }}
    </mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-checkbox
      color="primary"
      data-test="createAnother"
      [formControl]="createAnother"
    >
      {{ "labels.createAnother" | transloco }}
    </mat-checkbox>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
      <button mat-button data-test="cancel" (click)="onCancel()">
        {{ "buttons.cancel" | transloco | titlecase }}
      </button>
      <button
        *ngrxLet="submitting$ as submitting"
        mat-flat-button
        color="primary"
        data-test="createTicket"
        [class.spinner]="submitting"
        [disabled]="submitting"
        (click)="onCreateTicket()"
      >
        {{ "buttons.create" | transloco | titlecase }}
      </button>
    </div>
  </div>
</div>
