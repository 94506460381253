import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslocoModule } from '@ngneat/transloco';
import { MarkdownModule } from 'ngx-markdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GlobalInfoComponent } from './global-info.component';

@NgModule({
  declarations: [GlobalInfoComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexLayoutModule,
    LetDirective,
    PushPipe,
    NgxSkeletonLoaderModule,
    MarkdownModule.forChild(),
  ],
  exports: [GlobalInfoComponent],
})
export class GlobalInfoModule {}
