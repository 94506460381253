import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MtxLoaderModule } from '@ng-matero/extensions/loader';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NotifComponent } from './notif.component';

@NgModule({
  declarations: [NotifComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
    LetDirective,
    PushPipe,
    MtxLoaderModule,
  ],
  exports: [NotifComponent],
})
export class NotifModule {}
