import { NavBarLink } from './components/navbar';

export const ALLOWED_PAGE_FILE_EXT = ['.md'];
export const ALLOWED_DATA_FILE_EXT = [
  // 'application/vnd.ms-excel', // Excel Files 97-2003 (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel Files 2007+ (.xlsx)
];

export const EMPTY = '';
export const NOT_APPLICABLE = 'N/A';
export const FEATURES: NavBarLink[] = [];

export enum DataType {
  TECHNOLOGIES = 'technologies',
  PRICES = 'prices',
  FACTORS = 'factors',
  SOURCING = 'green-sourcing',
  RENEWABLE = 'weather',
  PROFILES = 'renewable-profiles',
}

export enum SourceType {
  RAW = 'raw',
  AGGREGATED = 'aggregated',
}
