function hoursToDate(hours: number): Date {
  return new Date(hoursToSeconds(hours) * 1000);
}

function hoursToSeconds(hours: number): number {
  return hours * 3600;
}

function utcDateToMonth(date: Date): string {
  return String(getSplittedDayDateMonth(date).pop());
}

function getSplittedDayDateMonth(date: Date): string[] {
  return getDayDateMonthFragment(date).split(getDayDateMonthSplitter());
}

function getDayDateMonthFragment(date: Date): string {
  return getDateFragments(date)[0].trim();
}

function getDateFragments(date: Date): string[] {
  return date.toUTCString().split(getUTCSplitter());
}

function getTimeFragment(date: Date): string {
  return getDateFragments(date)[1];
}

function utcDateToHour(date: Date): string {
  return getTimeFragment(date).split(getTimeSplitter())[0].trim();
}

function getTimeSplitter(): string {
  return ':';
}

function getDayDateMonthSplitter(): string {
  return ' ';
}

function getUTCSplitter(): string {
  return '1970';
}

function utcDateToDay(date: Date): string {
  return getSplittedDayDateMonth(date)[1];
}

function hoursToMoment(hours: number): Moment {
  const date = hoursToDate(hours);
  return {
    day: utcDateToDay(date),
    month: utcDateToMonth(date),
    hour: utcDateToHour(date),
  };
}

export const TimeUtils = {
  hoursToDate,
  utcDateToMonth,
  utcDateToDay,
  utcDateToHour,
  hoursToMoment,
};

export interface Moment {
  readonly hour: string;
  readonly day: string;
  readonly month: string;
}
