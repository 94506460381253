<div
  fxLayout="column"
  [formGroup]="uploadForm"
  *ngrxLet="uploadingData$ as uploading"
>
  <h2 data-test="title">{{ "dialog.titles.uploadSourceData" | transloco }}</h2>
  <mat-form-field>
    <mat-label>{{
      "labels.sourceDataModule" | transloco | titlecase
    }}</mat-label>
    <mat-select
      formControlName="module"
      data-test="source-data-module"
      (selectionChange)="loadModuleAssociations($event.value)"
    >
      <mat-option [value]="defaultValue" disabled="true">{{
        defaultValue
      }}</mat-option>
      <mat-option
        *ngFor="let module of modules$ | ngrxPush"
        [attr.data-test]="'option-type-' + module"
        [value]="module"
      >
        {{ "landing.name." + module | transloco }}
      </mat-option>
    </mat-select>
    <mat-progress-bar
      mode="indeterminate"
      data-test="source-data-module-load"
      *ngIf="loadingModule$ | ngrxPush"
    ></mat-progress-bar>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "labels.sourceDataType" | transloco | titlecase }}</mat-label>
    <mat-select
      formControlName="type"
      data-test="source-data-type"
      (selectionChange)="loadTypeSource($event.value)"
    >
      <mat-option [value]="defaultValue" disabled="true">{{
        defaultValue
      }}</mat-option>
      <mat-option
        *ngFor="let subType of subTypes$ | ngrxPush"
        [attr.data-test]="'option-sub-type-' + subType"
        [value]="subType"
        >{{ subType }}</mat-option
      >
    </mat-select>
    <mat-progress-bar
      mode="indeterminate"
      data-test="source-data-type-load"
      *ngIf="loadingType$ | ngrxPush"
    ></mat-progress-bar>
  </mat-form-field>
  <mat-form-field fxFlex *ngIf="!(hasNameOfClient$ | ngrxPush)">
    <mat-label>{{ "labels.source" | transloco }}</mat-label>
    <input matInput formControlName="source" data-test="source-data-author" />
    <mat-progress-bar
      mode="indeterminate"
      data-test="source-data-author-load"
      *ngIf="loadingSource$ | ngrxPush"
    ></mat-progress-bar>
    <mat-error data-test="source-data-author-error">
      {{ "errors.fieldIsRequired" | transloco }}
    </mat-error>
  </mat-form-field>
  <mat-form-field fxFlex *ngIf="hasNameOfClient$ | ngrxPush">
    <mat-label>{{ "labels.nameOfClient" | transloco }}</mat-label>
    <input
      matInput
      formControlName="nameOfClient"
      data-test="source-data-nameOfClient"
    />
    <mat-error data-test="source-data-nameOfClient-error">
      {{ "errors.fieldIsRequired" | transloco }}
    </mat-error>
  </mat-form-field>
  <tdb-file-upload
    formControlName="file"
    [acceptedFormats]="allowedFileExtns"
  ></tdb-file-upload>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <button mat-button data-test="cancel" (click)="onCancel()">
      {{ "buttons.cancel" | transloco | titlecase }}
    </button>
    <button
      mat-flat-button
      color="primary"
      data-test="upload-source-data"
      [class.spinner]="uploading"
      [disabled]="blockSubmit()"
      (click)="onUploadSourceData()"
    >
      {{ "buttons.upload" | transloco | titlecase }}
    </button>
  </div>
</div>
