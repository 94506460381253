import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ActiveTypeComponent } from './active-type.component';

@NgModule({
  declarations: [ActiveTypeComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslocoModule,
    MatButtonModule,
    FlexLayoutModule,
    LetDirective,
    PushPipe,
    MatMenuModule,
  ],
  exports: [ActiveTypeComponent],
})
export class ActiveTypeModule {}
