import { Injectable } from '@angular/core';
import { DataType } from '@tdb/app/app.tokens';
import { SourceExporter } from '@tdb/app/state/export';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { Coerce } from '@tdb/utils';
import {
  Notification,
  NotificationType,
  SimpleNotification,
} from './notification.state';
import { NotificationStore } from './notification.store';
import { finalize, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

type HandlerFn = (notification: Notification) => Observable<unknown>;
@Injectable({ providedIn: 'root' })
export class NotificationHandler {
  constructor(
    private readonly sourceExporter: SourceExporter,
    private readonly endpoints: EndpointProviderExt,
    private readonly notifs: NotificationStore,
  ) {}

  handle(notification: Notification): void {
    this.notifs.update(notification.id, { processing: true });
    this.getHandler(notification)(notification)
      .pipe(
        take(1),
        finalize(() => this.finalizeHandling(notification)),
      )
      .subscribe();
  }

  private finalizeHandling(notification: Notification): void {
    this.notifs.update(notification.id, { processing: false });
    this.requestMarkAsRead(notification);
  }

  private requestMarkAsRead(notification: Notification): void {
    this.notifs.markAsRead(notification.id).pipe(take(1)).subscribe();
  }

  private getHandler(notification: Notification): HandlerFn {
    return this.notifTypeHandlerFnBag[notification.type] || (() => of({}));
  }

  private get notifTypeHandlerFnBag(): Record<NotificationType, HandlerFn> {
    return {
      [NotificationType.EXPORT_FINISHED]:
        this.handlePriceExportFinished.bind(this),
      [NotificationType.EXPORT_FAILED]: () => of({}),
      [NotificationType.UPLOAD_PENDING]: () => of({}),
      [NotificationType.UPLOAD_PROCESSING]: () => of({}),
      [NotificationType.UPLOAD_FINISHED]: () => of({}),
      [NotificationType.UPLOAD_FAILED]: this.handleUploadFailed.bind(this),
    };
  }

  private handlePriceExportFinished(
    notification: SimpleNotification,
  ): Observable<unknown> {
    return this.sourceExporter.sync(
      this.endpoints.forSourceDataExportRaw(DataType.PRICES),
      {
        commodity: this.extractCommodity(notification),
        filename: this.extractFilename(notification),
      },
    );
  }

  private handleUploadFailed(
    notification: SimpleNotification,
  ): Observable<unknown> {
    return this.sourceExporter.sync(this.endpoints.forSourceDataLogs(), {
      filename: this.extractFilename(notification),
      module: this.extractModule(notification),
      item: this.extractItem(notification),
    });
  }

  private extractFilename(from: SimpleNotification): string {
    return Coerce.string(Coerce.obj(from.data).filename);
  }

  private extractModule(from: SimpleNotification): string {
    return Coerce.string(Coerce.obj(from.data).module);
  }

  private extractItem(from: SimpleNotification): string {
    return Coerce.string(Coerce.obj(from.data).item);
  }

  private extractCommodity(from: SimpleNotification): string {
    return Coerce.obj(from.data).commodity;
  }
}
