<div data-test="frame" fxLayout="column" fxLayoutGap="16px">
  <tdb-navbar
    data-test="navbar"
    [loggedIn]="loggedIn"
    [links]="links"
    (login)="login.emit()"
    (logout)="logout.emit()"
    (goto)="goto.emit($event)"
    (home)="home.emit()"
  ></tdb-navbar>
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex="90" class="frame-content">
      <ng-content></ng-content>
    </div>
  </div>
  <tdb-footer *ngIf="loggedIn"></tdb-footer>
</div>
