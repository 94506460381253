<tdb-frame
  [loggedIn]="(loggedIn$ | ngrxPush)!"
  [links]="FEATURES"
  (login)="onLogin()"
  (logout)="onLogout()"
  (home)="onHome()"
  (goto)="onGoto($event)"
>
  <router-outlet></router-outlet>
</tdb-frame>
