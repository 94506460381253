import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';
import { JiraFormComponent } from './jira-form.component';

@NgModule({
  declarations: [JiraFormComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslocoModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ReactiveComponentModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [JiraFormComponent],
})
export class JiraFormModule {}
