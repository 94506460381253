import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { PasteModule } from '@tdb/directives/paste';
import { MultiInputsComponent } from './multi-inputs.component';

@NgModule({
  declarations: [MultiInputsComponent],
  imports: [
    CommonModule,
    LetDirective,
    PushPipe,
    ReactiveFormsModule,
    PasteModule,
  ],
  exports: [MultiInputsComponent],
})
export class MultiInputsModule {}
