import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NotifsModule } from '../notifs/notifs.module';
import { NotifsTriggerComponent } from './notifs-trigger.component';

@NgModule({
  declarations: [NotifsTriggerComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    NotifsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    LetDirective,
    PushPipe,
  ],
  exports: [NotifsTriggerComponent],
})
export class NotifsTriggerModule {}
