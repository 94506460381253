<div
  fxLayout="column"
  [formGroup]="uploadForm"
  *ngrxLet="uploading$ as uploading"
>
  <h2 data-test="title">{{ "dialog.titles.uploadInfoContent" | transloco }}</h2>
  <tdb-file-upload
    formControlName="file"
    [disabled]="uploading"
    [acceptedFormats]="allowedFileExtns"
  ></tdb-file-upload>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <button mat-button data-test="cancel" (click)="onCancel()">
      {{ "buttons.cancel" | transloco | titlecase }}
    </button>
    <button
      mat-flat-button
      color="primary"
      data-test="upload-general-info"
      [class.spinner]="uploading"
      [disabled]="uploading || !uploadForm.valid"
      (click)="onUploadGeneralInfo()"
    >
      {{ "buttons.upload" | transloco | titlecase }}
    </button>
  </div>
</div>
