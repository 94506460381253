import { Inject, Injectable } from '@angular/core';
import { CognitoUser } from '@aws-amplify/auth';
import { AuthService } from '@tdb/core/auth';
import { Coerce } from '@tdb/utils';
import { WebSocket } from './websocket.wrapper';
import { WS_CONFIG_TOKEN } from './websocket.tokens';
import { WebSocketConfig, WebSocketData } from './websocket.model';
import { WebSocketSubjectConfig } from 'rxjs/webSocket';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class WebSocketManager {
  private readonly connection$ = this.getConnection();

  constructor(
    @Inject(WS_CONFIG_TOKEN) readonly wsConfig: WebSocketConfig,
    private authService: AuthService,
  ) {}

  getConnection(): Observable<unknown> {
    return this.authService.user$.pipe(
      switchMap((user: CognitoUser) =>
        WebSocket.create(this.buildConfig(this.wsConfig.url, user)),
      ),
    );
  }

  addHandler(): Observable<unknown> {
    return this.connection$;
  }

  deserialize(e: MessageEvent<string>): WebSocketData {
    return JSON.parse(e.data);
  }

  private buildConfig(
    url: string,
    user: CognitoUser,
  ): WebSocketSubjectConfig<WebSocketData> {
    return {
      url: this.attachAuthTokens(url, user),
      deserializer: this.deserialize.bind(this),
    };
  }

  private attachAuthTokens(url: string, user: CognitoUser): string {
    return [url, this.buildQueryParams(user)].join('?');
  }

  private buildQueryParams(user: CognitoUser): string {
    const [idToken, accessToken] = this.getTokens(user);
    return [
      `idToken=${idToken}`,
      `accessToken=${accessToken}`,
      `x-correlation-id=${Coerce.string(uuid())}`,
    ].join('&');
  }

  private getTokens(user: CognitoUser): [string, string] {
    const userSession = Coerce.obj(user.getSignInUserSession());
    return [
      userSession.getIdToken().getJwtToken(),
      userSession.getAccessToken().getJwtToken(),
    ];
  }
}
