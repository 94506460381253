<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  @enter
  @leave
  *ngIf="isDataType$ | async"
>
  <span class="active-type-prefix">{{ "landing.activeType" | transloco }}</span>
  <button
    data-test="activeButton"
    mat-button
    color="primary"
    [matMenuTriggerFor]="menu"
    *ngIf="urlHead$ | async as head"
  >
    <mat-icon color="primary" data-test="activeTypeIcon">
      {{ "landing.icon." + head | transloco }}
    </mat-icon>
    {{ "landing.name." + head | transloco }}
  </button>
  <mat-menu #menu="matMenu" overlapTrigger>
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      *ngrxLet="urlHead$ as head"
    >
      <button
        *ngFor="let type of types"
        mat-menu-item
        [attr.data-test]="'type-' + type"
        [class.active]="type === head"
        (click)="onClickOfType(type)"
      >
        {{ "landing.name." + type | transloco }}
      </button>
    </div>
  </mat-menu>
</div>
