import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MultiInputsModule } from '@tdb/components/multi-inputs';
import { PasteModule } from '@tdb/directives/paste';
import { PriceLoadComponent } from './price-load.component';

@NgModule({
  declarations: [PriceLoadComponent],
  imports: [
    CommonModule,
    MultiInputsModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatRippleModule,
    PasteModule,
  ],
  exports: [PriceLoadComponent],
})
export class PriceLoadModule {}
