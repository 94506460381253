import { IAnimationOptions } from 'angular-animations/common/interfaces';

function createAnimationOptions(
  anchor: string,
  delay?: number,
): IAnimationOptions {
  return { anchor, duration: 300, delay };
}

export const AnimaUtils = { createAnimationOptions };
