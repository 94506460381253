import { Injectable } from '@angular/core';
import { Query, QueryConfig } from '@datorama/akita';
import { SupportState } from './support.state';
import { SupportStore } from './support.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({})
export class SupportQuery extends Query<SupportState> {
  constructor(store: SupportStore) {
    super(store);
  }
}
