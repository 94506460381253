import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavBarLink } from './navbar.model';

@Component({
  selector: 'tdb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() loggedIn = false;
  @Input() links: NavBarLink[] = [];

  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() home = new EventEmitter();
  @Output() goto = new EventEmitter();

  onLogout(): void {
    this.logout.emit();
  }

  onLogin(): void {
    this.login.emit();
  }

  onHome(): void {
    this.home.emit();
  }
}
