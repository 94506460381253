import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FEATURES } from './app.tokens';
import { AuthService } from './core/auth';
import { NavBarLink } from './components/navbar';
import { NotificationStore } from './notifications/state';
import { WebSocketManager } from './services/websocket';
import { SourceDataStore } from './state/source-data';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'tdb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  readonly FEATURES: NavBarLink[] = FEATURES;
  adminAccess$: Observable<boolean>;
  user$: Observable<unknown>;
  loggedIn$: Observable<boolean>;

  // eslint-disable-next-line max-params
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly sourceDataStore: SourceDataStore,
    private readonly wsManager: WebSocketManager,
    private readonly notifs: NotificationStore,
  ) {
    this.adminAccess$ = authService.adminAccess$.pipe(untilDestroyed(this));
    this.user$ = authService.user$.pipe(untilDestroyed(this));
    this.loggedIn$ = this.authService.loggedIn$.pipe(
      map((value) => !!value),
      untilDestroyed(this),
    );
  }

  ngAfterViewInit(): void {
    this.subscribeToWebsocket();
  }

  onLogin(): void {
    this.authService.login();
  }

  onLogout(): void {
    this.authService.logout();
  }

  onGoto(where: string): void {
    void this.router.navigateByUrl(where);
  }

  onHome(): void {
    void this.router.navigateByUrl('home');
  }

  private subscribeToWebsocket(): void {
    this.user$.subscribe(() => {
      this.requestNotifications();
      this.requestModuleUploads();
    });
    this.wsManager
      .addHandler()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.requestNotifications());
  }

  private requestModuleUploads(): void {
    this.sourceDataStore.requestForModules().subscribe();
  }

  private requestNotifications(): void {
    this.notifs.getAll().pipe(take(1)).subscribe();
  }
}
