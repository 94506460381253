import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { InfoComponent } from './info.component';

@NgModule({
  declarations: [InfoComponent],
  imports: [MatIconModule, TranslocoModule, MatButtonModule, FlexLayoutModule],
  exports: [InfoComponent],
})
export class InfoModule {}
