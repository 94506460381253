import {
  Component,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@tdb/services/snackbar';
import { Coerce } from '@tdb/utils';
import { FormBuilder } from '@ngneat/reactive-forms';
import { AboutQuery, AboutStore, UploadGlobalInfoRequest } from '../../state';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

const DEFAULT_FILE_EXTS = ['.md'];

@Component({
  selector: 'tdb-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFormComponent {
  readonly uploading$: Observable<boolean>;
  readonly uploadForm = this.builder.group<UploadGlobalInfoRequest>(
    this.getInitialValues(),
  );
  allowedFileExtns = DEFAULT_FILE_EXTS;

  // eslint-disable-next-line
  constructor(
    private builder: FormBuilder,
    private aboutStore: AboutStore,
    private dialogRef: MatDialogRef<UploadFormComponent>,
    private aboutQuery: AboutQuery,
    private snacks: SnackbarService,
  ) {
    this.uploading$ = this.aboutQuery.select('uploadingInfo');
  }

  onUploadGeneralInfo(): void {
    if (this.uploadForm.valid) {
      this.doCreateUploadInfo();
    }
  }

  private doCreateUploadInfo(): void {
    const file = Coerce.obj<File>(this.uploadForm.value.file);
    this.aboutStore
      .uploadGlobalInfo(file)
      .pipe(take(1))
      .subscribe({
        next: () => this.onSuccess(file.name),
        error: () => this.onFailure(),
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(): void {
    this.dialogRef.close();
  }

  private onSuccess(filename: string): void {
    this.snacks.openOK(this.createSuccessMsg(filename));
    this.dialogRef.close();
  }

  private onFailure(): void {
    this.snacks.openNOK(this.createFailureMsg());
  }

  private createSuccessMsg(filename: string): string {
    return `You've uploaded "${filename}" as the global info page.`;
  }

  private createFailureMsg(): string {
    return 'Something went wrong while uploading the global info';
  }

  private getInitialValues(): UploadGlobalInfoRequest {
    return { file: undefined } as UploadGlobalInfoRequest;
  }
}
