import { isNumber } from '@datorama/akita';

function string(alleged: unknown, to = ''): string {
  return String(alleged ?? to);
}

function number(alleged: unknown, to = 0): number {
  return isNumber(alleged) ? Number(alleged) : to;
}

function object<T = unknown>(alleged: unknown): Record<string, T> {
  return (alleged as Record<string, T>) || {};
}

function array<T>(alleged: T[] | undefined | null): T[] {
  return alleged || [];
}

function fn<T>(alleged: ((_: T) => void) | undefined): (_: T) => void {
  return (
    alleged ||
    (() => {
      // do nothing
    })
  );
}

function fnArray<T, Y>(alleged: ((_: Y) => T[]) | undefined): (_: Y) => T[] {
  return alleged || (() => []);
}

function obj<T>(alleged: T | undefined | null): T {
  return alleged || ({} as T);
}

export const Coerce = {
  string,
  number,
  object,
  array,
  fnArray,
  fn,
  obj,
};
