import { Factors } from '@tdb/prices/state';
import { FilterCriteria } from '../filtration';

export interface ExportState {
  readonly exportingPoints?: boolean;
  readonly exportingPoint?: boolean;
  readonly exportingCurve?: boolean;
  readonly exportingAggregated?: boolean;
  readonly exportingPrices?: boolean;
  readonly exportingSource?: boolean;
}

export interface ExportResponse {
  readonly signed_url: string;
  readonly filename: string;
}

export enum ExportSource {
  POINTS = 'exportPoints',
  POINT = 'exportPoint',
  AGGREGATED = 'exportAggregated',
  CURVE = 'exportCurve',
}

export type ExportPricesRequest = FilterCriteria & Factors;

export interface ExportPriceSourceResponse extends ExportResponse {}

export interface AsyncExportSourceRsp {
  readonly message: string;
}
