import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective, PushPipe } from '@ngrx/component';

import { FileUploadModule } from '../file-upload';
import { UploadSourceDataComponent } from './upload-source-data.component';

@NgModule({
  declarations: [UploadSourceDataComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    FileUploadModule,
  ],
  exports: [UploadSourceDataComponent],
})
export class UploadSourceDataModule {}
