<div fxLayout="row" fxLayoutGap="8px">
  <div fxFlex="50" fxLayout="column" fxLayoutGap="8px">
    <ng-content></ng-content>
    <div
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center center"
      data-test="paster"
      class="price-load-target clickable"
      matRipple
      tdbPaste
      (peyst)="onPeyst($event)"
      (click)="onPasterClick()"
    >
      <mat-icon>content_paste</mat-icon>
      <span>
        {{ "buttons.pasteFromClipboard" | transloco }}
      </span>
    </div>
  </div>

  <tdb-multi-inputs
    fxFlex="50"
    class="price-load-inputs"
    data-test="3860"
    [formControl]="load"
    [length]="numberOfHours"
  ></tdb-multi-inputs>
</div>
