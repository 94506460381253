import { Coerce } from '@tdb/utils';
import {
  BENotif,
  NotificationType,
  NotifStatus,
  SimpleNotification,
} from './notification.state';

function toSimpleNotif(from: BENotif): SimpleNotification {
  return {
    id: from.uuid,
    body: from.body,
    subject: from.subject,
    read: isRead(from),
    data: from.data,
    date: new Date(from.received),
    type: extractType(from),
  } as SimpleNotification;
}

function extractType(from: BENotif): NotificationType {
  return Coerce.obj(from.data).type;
}

function isRead(from: BENotif): boolean {
  return from.status === NotifStatus.READ;
}

export const NotifMapper = {
  toSimpleNotif,
};
