import { Store, StoreConfig } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataType } from '@tdb/app/app.tokens';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { ExportState, ExportResponse } from './export.models';
import { SourceExporter } from './source.exporter';
import { FilterCriteria } from '../filtration';
import { Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'export' })
export class ExportStore extends Store<ExportState> {
  constructor(
    private http: HttpClient,
    private readonly endpoints: EndpointProviderExt,
    private readonly sourceExporter: SourceExporter,
  ) {
    super({});
  }

  /** @deprecated [for standardization] The method should not be used  */
  exportSourceXlsx(
    module: DataType,
    criteria: FilterCriteria,
  ): Observable<unknown> {
    return this.doExport(this.endpoints.forSourceDataExport(module), criteria);
  }
  exportSourceDataAsXlsx(
    module: DataType,
    criteria: FilterCriteria,
    typeCriteria: string,
  ): Observable<unknown> {
    return this.doExport(
      this.endpoints.forSourceDataExportXlsx(module, typeCriteria),
      criteria,
    );
  }
  exportSourceDataRaw(module: DataType, params = {}): Observable<unknown> {
    return this.syncExportSource(
      this.endpoints.forSourceDataExportRaw(module),
      params,
    );
  }

  exportPriceSource(
    isFiltered: boolean,
    criteria: FilterCriteria,
  ): Observable<unknown> {
    if (isFiltered) {
      return this.asyncExportSource(
        this.endpoints.forFilteredPriceSource(),
        criteria as Record<string, string>,
      );
    }
    return this.syncExportSource(
      this.endpoints.forSourceDataExportRaw(DataType.PRICES),
      criteria as Record<string, string>,
    );
  }

  /** @deprecated [for standardization] The method should not be used  */
  exportRenewableProfileSource(): Observable<unknown> {
    return this.syncExportSource(
      this.endpoints.forProfileDataExportRaw(DataType.PROFILES, 'RES Profile'),
      {},
    );
  }

  private doExport(
    endpoint: string,
    params: FilterCriteria,
  ): Observable<unknown> {
    this.setLoading(true);
    return this.http.get<ExportResponse>(endpoint, { params }).pipe(
      mergeMap((response) => this.sourceExporter.saveFile(response)),
      finalize(() => this.setLoading(false)),
    );
  }

  private syncExportSource(
    endpoint: string,
    params: Record<string, string>,
  ): Observable<unknown> {
    this.update({ exportingSource: true });
    return this.sourceExporter
      .sync(endpoint, params)
      .pipe(finalize(() => this.update({ exportingSource: false })));
  }

  private asyncExportSource(endpoint: string, params: Record<string, string>) {
    this.update({ exportingSource: true });
    return this.sourceExporter
      .async(endpoint, params)
      .pipe(finalize(() => this.update({ exportingSource: false })));
  }
}
