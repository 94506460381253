import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@tdb/services/snackbar';
import FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  AsyncExportSourceRsp,
  ExportPriceSourceResponse,
} from './export.models';

@Injectable({
  providedIn: 'root',
})
export class SourceExporter {
  constructor(
    private readonly http: HttpClient,
    private readonly snack: SnackbarService,
  ) {}

  async saveFile(rsp: ExportPriceSourceResponse): Promise<void> {
    const blob = await fetch(rsp.signed_url).then((r) => r.blob());
    FileSaver.saveAs(blob, rsp.filename);
  }

  sync(endpoint: string, params: Record<string, string>): Observable<unknown> {
    return this.http
      .get<ExportPriceSourceResponse>(endpoint, {
        params,
      })
      .pipe(mergeMap((rsp) => this.saveFile(rsp)));
  }

  async(endpoint: string, params: Record<string, string>): Observable<unknown> {
    return this.http
      .get<AsyncExportSourceRsp>(endpoint, {
        params,
      })
      .pipe(tap((rsp) => this.openSnackOnSuccessfulAsyncResponse(rsp)));
  }

  private openSnackOnSuccessfulAsyncResponse(rsp: AsyncExportSourceRsp) {
    this.snack.openOK(rsp.message);
  }
}
