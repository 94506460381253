<div
  *ngrxLet="mouseEnter$ as hovering"
  fxLayout="column"
  fxLayoutGap="16px"
  data-test="actionsFamily"
  (mouseleave)="onMouseLeave()"
>
  <div *ngIf="hovering" fxLayout="column" fxLayoutGap="16px">
    <button
      mat-fab
      color="primary"
      data-test="createTicket"
      [mtxTooltip]="createTicket"
      [mtxTooltipPosition]="'before'"
      (click)="onCreateTicket()"
      [@2in]
    >
      <mat-icon>contact_support</mat-icon>
    </button>
    <ng-template #createTicket>
      <div class="tip">
        {{ "tooltips.createTicket" | transloco }}
      </div>
    </ng-template>

    <button
      mat-fab
      color="primary"
      data-test="contribute"
      *ngIf="adminAccess$ | ngrxPush"
      [mtxTooltipPosition]="'before'"
      [mtxTooltip]="contribute"
      (click)="onUploadInfo()"
      [@1in]
    >
      <mat-icon>reviews</mat-icon>
    </button>
    <ng-template #contribute>
      <div class="tip">
        {{ "tooltips.uploadInfoContent" | transloco }}
      </div>
    </ng-template>

    <button
      mat-fab
      color="primary"
      data-test="uploadData"
      *ngIf="uploadAccess$ | ngrxPush"
      [mtxTooltipPosition]="'before'"
      [mtxTooltip]="uploadData"
      (click)="onUploadData()"
      [@1in]
    >
      <mat-icon>add_chart</mat-icon>
    </button>
    <ng-template #uploadData>
      <div class="tip">
        {{ "tooltips.uploadUpdatedData" | transloco }}
      </div>
    </ng-template>
  </div>
  <button
    *ngIf="hovering; else trigger"
    mat-fab
    data-test="close"
    class="footer-actions-close"
    @close
  >
    <mat-icon>expand_less</mat-icon>
  </button>
  <ng-template #trigger>
    <button
      mat-fab
      color="primary"
      data-test="footerActionsTrigger"
      (mouseenter)="onMouseEnter()"
    >
      <mat-icon>help_center</mat-icon>
    </button>
  </ng-template>
</div>
