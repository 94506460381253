import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  Notification,
  NotificationHandler,
  NotificationQuery,
  NotificationStore,
} from '@tdb/notifs/state';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'tdb-notifs',
  templateUrl: './notifs.component.html',
  styleUrls: ['./notifs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifsComponent {
  readonly hasUnread$: Observable<boolean> = this.selectHasUnread();
  readonly notifs$ = this.notifs.selectUnread();
  readonly loading$ = this.notifs.selectLoading();

  constructor(
    private readonly notifs: NotificationQuery,
    private readonly handler: NotificationHandler,
    private readonly notifStore: NotificationStore,
  ) {}

  onNotifClick(notif: Notification): void {
    this.handler.handle(notif);
  }

  onRefresh(): void {
    this.notifStore.getAll().pipe(take(1)).subscribe();
  }

  private selectNotifs(): Observable<Notification[]> {
    return this.notifs.selectUnread();
  }

  private selectHasUnread(): Observable<boolean> {
    return this.selectNotifs().pipe(map((notif) => !!notif.length));
  }
}
