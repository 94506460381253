<input
  #fileUpload
  type="file"
  class="tdb-file-upload-input"
  hidden
  data-cy="file-upload-input"
  [accept]="formats"
  (change)="onFileSelected($event)"
/>

<div
  class="tdb-file-upload-container prevent-select"
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngClass]="{ dragover: dragover }"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
>
  <mat-icon color="primary">{{ icon }}</mat-icon>

  <h2 class="tdb-file-upload-header">Drag & Drop</h2>

  <p class="tdb-file-upload-details">
    Your file here or <a (click)="onBrowse()">browse</a> to attach it
  </p>

  <p class="tdb-file-upload-hint">
    {{ hint }}
  </p>
</div>

<div
  class="tdb-file-upload-status"
  [ngClass]="{ 'tdb-file-upload-status-success': !!file }"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="0.1rem"
>
  <mat-icon *ngIf="!!file" inline>done_all</mat-icon>
  <span>{{ file?.name || "No file attached." }}</span>
</div>
