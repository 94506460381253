export interface SupportState {
  readonly submitting: boolean;
}

export interface SupportTicket {
  readonly type: TicketType;
  readonly summary: string;
  readonly description: string;
}

export enum TicketType {
  BUG = 'bug',
  INQUIRY = 'inquiry',
  FEATURE = 'feature',
  SUPPORT = 'support',
}

export interface CreateTicketRequest {
  readonly fields: SupportTicket & { issuetype: TicketType };
}

export interface CreateTicketResponse {
  readonly id: string;
  readonly key: string;
}
