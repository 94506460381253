import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';
import { FooterActionsComponent } from './footer-actions.component';

@NgModule({
  declarations: [FooterActionsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    FlexLayoutModule,
    ReactiveComponentModule,
    MtxTooltipModule,
  ],
  exports: [FooterActionsComponent],
})
export class FooterActionsModule {}
