import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AboutQuery, AboutStore } from '../../state';

@Component({
  selector: 'tdb-global-info',
  templateUrl: './global-info.component.html',
  styleUrls: ['./global-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalInfoComponent {
  readonly info$ = this.aboutQuery.select('infoContent');
  readonly loading$ = this.aboutQuery.select('loadingInfo');

  constructor(private aboutStore: AboutStore, private aboutQuery: AboutQuery) {
    this.getInfoContent();
  }

  private getInfoContent(): void {
    this.aboutStore.downloadGlobalInfo().subscribe();
  }
}
