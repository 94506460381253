import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, StoreConfig, transaction } from '@datorama/akita';
import { CanAverageOn } from '@tdb/base';
import { FilterCriteria } from '@tdb/app/state/filtration';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { DataType } from '@tdb/app/app.tokens';
import fill from 'fill-range';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  AggregatablePriceKey,
  Commodity,
  Factors,
  GetPricesResponse,
  GetSourceInfoResponse,
  initializeState,
  PriceState,
  WeightsInfo,
} from './price.state';
import { PriceUtils } from './price.utils';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'price' })
export class PriceStore
  extends Store<PriceState>
  implements CanAverageOn<AggregatablePriceKey>
{
  constructor(
    private http: HttpClient,
    private endpoints: EndpointProviderExt,
  ) {
    super(initializeState());
  }

  setAverageOn(averageOn: AggregatablePriceKey[]): void {
    this.update({ averageOn });
  }

  getPrices(): Observable<unknown> {
    this.setLoading(true);
    return this.requestPrices({}).pipe(finalize(() => this.setLoading(false)));
  }

  filterPrices(params: FilterCriteria): Observable<unknown> {
    this.setFilteringState(true);
    this.overrideFactorize(params);
    return this.requestPrices(params).pipe(
      tap(() => this.update({ activeFilter: params })),
      finalize(() => this.setFilteringState(false)),
    );
  }

  updateFactors(factors: Partial<Factors>): void {
    this.update({ factors: { ...this.getFactors(), ...factors } });
  }

  updateWeights(commodity: string, values: number[]): Observable<unknown> {
    return this.requestWeights({ commodity, values });
  }

  updateWeightsUsingInfo(info: WeightsInfo): Observable<unknown> {
    return this.requestWeights(info);
  }

  refresh(): Observable<unknown> {
    this.update({ refreshing: true });
    return this.requestPrices(this.getActiveFilter()).pipe(
      finalize(() => this.update({ refreshing: false })),
    );
  }

  getSourceInfo(type: string): Observable<unknown> {
    this.setLoadingSource(true);
    return this.http
      .get<GetSourceInfoResponse>(
        this.endpoints.forSourceDataInfo(DataType.PRICES, type),
      )
      .pipe(
        tap((source) => this.update({ source })),
        finalize(() => this.setLoadingSource(false)),
      );
  }

  averageOn(
    averageOn: AggregatablePriceKey[],
    criteria: FilterCriteria,
  ): Observable<unknown> {
    this.startAverageOn();
    return this.http
      .get<GetPricesResponse>(this.endpoints.forAveragePrices(), {
        params: { averageOn, ...this.getDefaultedCriteria(criteria) },
      })
      .pipe(
        tap((rsp) => this.update({ aggregated: rsp.data })),
        finalize(() => this.setLoading(false)),
      );
  }

  setActiveCommodity(activeCommodity: Commodity): void {
    this.update({ activeCommodity });
  }

  private getDefaultedCriteria(criteria: FilterCriteria): FilterCriteria {
    return { ...criteria, commodity: PriceUtils.coerceCommodity(criteria) };
  }

  private requestWeights(info: Partial<WeightsInfo>): Observable<unknown> {
    this.update({ weighting: true });
    return this.http
      .post(this.endpoints.forUpdateWeights(), info)
      .pipe(finalize(() => this.update({ weighting: false })));
  }

  @transaction()
  private startAverageOn(): void {
    this.setLoading(true);
    this.update({ aggregated: [] });
  }

  private getActiveFilter(): FilterCriteria {
    return this.getValue().activeFilter;
  }

  private getFactors(): Factors {
    return this.getValue().factors;
  }

  @transaction()
  private setFilteringState(should: boolean): void {
    this.setLoading(should);
    this.update({ filtering: should });
  }

  @transaction()
  private overrideFactorize(params: FilterCriteria): void {
    const commodity = PriceUtils.coerceCommodity(params);
    if (PriceUtils.disableFactorize(commodity)) {
      this.update({ factorize: false });
    }
  }

  private requestPrices(params: FilterCriteria): Observable<unknown> {
    return this.http
      .get<GetPricesResponse>(this.endpoints.forSourceData(DataType.PRICES), {
        params,
      })
      .pipe(
        tap((response) => this.onGetPricesSuccess(response)),
        catchError((error) => this.onError(error)),
      );
  }

  @transaction()
  private setLoadingSource(loadingSource: boolean): void {
    this.update({ loadingSource });
  }

  private onGetPricesSuccess(response: GetPricesResponse): void {
    this.update({
      prices: response.data,
      years: this.fillYears(response.startYear, response.endYear),
      nature: response.type,
    });
  }

  private fillYears(from: number, to: number): number[] {
    return fill(Number(from), Number(to));
  }

  private onError(error: unknown): Observable<unknown> {
    this.setError(error);
    return throwError(error);
  }
}
