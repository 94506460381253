import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, StoreConfig } from '@datorama/akita';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { DataType, NOT_APPLICABLE } from '@tdb/app/app.tokens';
import { Coerce } from '@tdb/utils';
import {
  SourceInfo,
  SourceDataState,
  getInitialValues,
  ModuleConfigResponse,
  FilterConfigResponse,
  UploadSourceDataRequest,
  UploadSourceDataResponse,
} from './source-data.state';
import { Subject, throwError, Observable } from 'rxjs';
import {
  map,
  tap,
  take,
  finalize,
  mergeMap,
  takeUntil,
  catchError,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sourceData' })
export class SourceDataStore extends Store<SourceDataState> {
  readonly cancelOngoingRequests = new Subject<unknown>();

  constructor(
    private http: HttpClient,
    private endpoints: EndpointProviderExt,
  ) {
    super(getInitialValues());
  }

  triggerLoadingDataType(): void {
    this.cancelOngoingRequests.next(true);
    this.update({ loadingDataType: true });
    this.update({ loadingDataType: false });
  }

  requestForModules(): Observable<string[]> {
    this.update({ loadingDataModules: true });
    this.cancelOngoingRequests.next(true);
    return this.http
      .get<ModuleConfigResponse>(this.endpoints.forSourceDataModules())
      .pipe(
        take(1),
        takeUntil(this.cancelOngoingRequests),
        map((response) => Coerce.array(response.modules)),
        tap((modules) => this.update({ dataModules: modules })),
        finalize(() => this.update({ loadingDataModules: false })),
      );
  }

  requestForTypes(module: string): Observable<string[]> {
    this.update({ loadingDataType: true });
    this.cancelOngoingRequests.next(true);
    let url = this.endpoints.forSourceDataFilter(module);
    if (this.shouldUseNewEndpoint(module)) {
      url = this.endpoints.forSourceDataUploadCategories(module);
    }
    return this.http
      .get<FilterConfigResponse>(url, { params: { slim: 2 } }) // load filtered types
      .pipe(
        take(1),
        takeUntil(this.cancelOngoingRequests),
        map((response) => Coerce.array(response.types)),
        tap((types) => this.update({ dataTypes: types })),
        catchError((error) => this.onGetConfigError(error)),
        finalize(() => this.update({ loadingDataType: false })),
      );
  }

  requestForSource(module: string, type: string): Observable<SourceInfo> {
    this.update({ loadingDataSource: true });
    this.cancelOngoingRequests.next(true);
    return this.http
      .get<SourceInfo>(this.endpoints.forSourceDataInfo(module, type))
      .pipe(
        take(1),
        takeUntil(this.cancelOngoingRequests),
        tap(({ source }) => this.update({ dataSource: source })),
        // catchError((error) => this.onGetConfigError(error)),
        finalize(() => this.update({ loadingDataSource: false })),
      );
  }

  uploadSourceData<T extends UploadSourceDataResponse>(
    req: UploadSourceDataRequest,
  ): Observable<T> {
    this.update({ uploadingSourceData: true });
    const coerced_file = Coerce.obj(req.file);
    return this.http
      .get<T>(this.endpoints.forSourceDataUpload(req.module), {
        params: this.getQueryParams(req, coerced_file),
      })
      .pipe(
        take(1),
        mergeMap((response) =>
          this.http.put<T>(response.signed_url, coerced_file),
        ),
        finalize(() => this.update({ uploadingSourceData: false })),
      );
  }

  private onGetConfigError(error: unknown): Observable<string[]> {
    this.setError(error);
    return throwError(error);
  }

  private getQueryParams(
    req: UploadSourceDataRequest,
    file: File,
  ): Record<string, string> {
    const params = {
      filename: file.name,
      source: req.source,
      client: req.nameOfClient,
      item: req.type,
    };
    return this.filterParams(params);
  }

  private filterParams(params: Record<string, string>): Record<string, string> {
    return Object.entries(params)
      .filter(([, value]) => this.checkValidity(value))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  private checkValidity(value: unknown): boolean {
    return [!!String(value).trim(), value !== NOT_APPLICABLE].every(Boolean);
  }

  private shouldUseNewEndpoint(module: string): boolean {
    return [
      DataType.PRICES,
      DataType.FACTORS,
      DataType.TECHNOLOGIES,
      DataType.SOURCING,
    ]
      .map(String)
      .includes(module);
  }
}
