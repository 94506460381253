import { Inject, Injectable } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from '@tdb/environment';

@Injectable({ providedIn: 'root' })
export class EndpointProviderService {
  constructor(@Inject(ENV_CONFIG) private env: EnvironmentConfig) {}

  /** @deprecated [for standardization] The method should not be used  */
  forUpdateWeights(): string {
    return this.getUrlTemplate('updateWeights');
  }
  /** @deprecated [for standardization] The method should not be used  */
  forExportPrices(): string {
    return this.getUrlTemplate('exportPrices');
  }

  forCreateTicket(): string {
    return this.getUrlTemplate('createTicket');
  }
  /** @deprecated [for standardization] The method should not be used  */
  forPriceMaster(): string {
    return this.getUrlTemplate('priceMaster');
  }
  /** @deprecated [for standardization] The method should not be used  */
  forAveragePrices(): string {
    return this.getUrlTemplate('averagePrices');
  }

  protected getUrlTemplate(urlKey: string): string {
    const endpoint = this.env.endpoints[urlKey];
    return `${this.env.baseUrl}/${endpoint}`;
  }
}
