import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { translate } from '@ngneat/transloco';
import { Coerce } from '@tdb/app/utils';

const DEFAULT_ERROR = 'snackbar.error.generic.default';
@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snack: MatSnackBar) {}

  openNOKWithKey(key: string | undefined, module = 'generic'): void {
    const message = this.getNOKMessage(key, module);
    this.snack.open(message, undefined, this.getNOKSnackbarConfig());
  }

  openNOK(message: string | undefined): void {
    message = Coerce.string(message, translate(DEFAULT_ERROR));
    this.snack.open(message, undefined, this.getNOKSnackbarConfig());
  }

  openOK(message: string): void {
    this.snack.open(message, undefined, this.getOKSnackbarConfig());
  }

  private getNOKMessage(key: string | undefined, module: string): string {
    key = Coerce.string(key, 'default');
    let messageKey = `snackbar.error.${module}.${key}`;
    if (key === 'default') messageKey = DEFAULT_ERROR;
    return translate(messageKey);
  }

  private getNOKSnackbarConfig(): MatSnackBarConfig {
    return { ...this.getCommonConfig(), panelClass: 'tdb-snack-nok' };
  }

  private getOKSnackbarConfig(): MatSnackBarConfig {
    return { ...this.getCommonConfig(), panelClass: 'tdb-snack-ok' };
  }

  private getCommonConfig(): MatSnackBarConfig {
    return { duration: 4000 };
  }
}
