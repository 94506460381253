<div class="tdb-card">
  <h1 data-test="info-title">{{ "labels.infoTitle" | transloco }}</h1>
  <div
    *ngIf="loading$ | ngrxPush; else loaded"
    fxLayout="column"
    fxLayoutGap="8px"
    fxLayoutAlign="start stretch"
    data-test="info-skelly"
    class="info-skelly"
  >
    <ngx-skeleton-loader fxFlex count="14"></ngx-skeleton-loader>
  </div>
  <ng-template #loaded>
    <markdown
      class="info"
      data-test="info-content"
      [data]="info$ | ngrxPush"
    ></markdown>
  </ng-template>
</div>
