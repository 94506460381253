import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@oculus/auth/amplify';

import { GlobalInfoComponent } from './+about/components/global-info';
import { DataType } from './app.tokens';
import { EggComponent } from './components/egg';
import { LandingPageComponent } from './components/landing-page';

const routes: Routes = [
  {
    path: DataType.TECHNOLOGIES,
    loadChildren: () =>
      import('./+technologies/technologies.module').then(
        (m) => m.TechnologiesModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: DataType.PRICES,
    loadChildren: () =>
      import('./+prices/prices.module').then((m) => m.PricesModule),
    canActivate: [AuthGuard],
  },
  {
    path: DataType.FACTORS,
    loadChildren: () =>
      import('./+factors/factors.module').then((m) => m.FactorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: DataType.SOURCING,
    loadChildren: () =>
      import('./+sourcing/sourcing.module').then((m) => m.SourcingModule),
    canActivate: [AuthGuard],
  },
  {
    path: DataType.RENEWABLE,
    loadChildren: () =>
      import('./+renewable/renewable.module').then((m) => m.RenewableModule),
    canActivate: [AuthGuard],
  },
  {
    path: DataType.PROFILES,
    loadChildren: () =>
      import('./+profiles/profiles.module').then((m) => m.ProfilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'globalInfo',
    component: GlobalInfoComponent,
    canActivate: [AuthGuard],
  },

  { path: 'home', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', redirectTo: 'home' },
  { path: 'egg', component: EggComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
