import { EntityState } from '@datorama/akita';

export enum NotificationType {
  EXPORT_FINISHED = 'exportOk',
  EXPORT_FAILED = 'exportNotOk',
  UPLOAD_PENDING = 'uploadSourcePending',
  UPLOAD_PROCESSING = 'uploadSourceProcessing',
  UPLOAD_FINISHED = 'uploadSourceOk',
  UPLOAD_FAILED = 'uploadSourceNotOk',
}

export interface SimpleNotification<T = PossibleNotifData> {
  readonly id: string;
  readonly subject: string;
  readonly body: string;
  readonly date: Date;
  readonly type: NotificationType;
  readonly data?: T;
  readonly read?: boolean;
  readonly processing?: boolean;
}

export type Notification = SimpleNotification;

export interface NotificationState extends EntityState<Notification> {}

export type GetNotifsResponse = BENotif[];

export interface BENotif {
  readonly uuid: string;
  readonly body: string;
  readonly status: NotifStatus;
  readonly subject: string;
  readonly received: string;
  readonly data?: PossibleNotifData;
}

export enum NotifStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export type PossibleNotifData = ExportSuccessData | undefined;

export interface ExportSuccessData {
  readonly requestTimestamp: string;
  readonly type: NotificationType;
  readonly s3Key: string;
  readonly commodity: string;
  readonly item: string | undefined;
  readonly module: string | undefined;
  readonly filename: string | undefined;
}
