import { Injectable } from '@angular/core';
import { CanSelectAggregated } from '@tdb/base';
import { Coerce, GeneralUtils } from '@tdb/utils';
import { filterNilValue, Query } from '@datorama/akita';
import {
  Price,
  Factors,
  Commodity,
  PriceState,
  PriceSource,
} from './price.state';
import { PriceStore } from './price.store';
import { PriceFactorizer, PriceFactorizerData } from './price.factorizer';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PriceQuery
  extends Query<PriceState>
  implements CanSelectAggregated<Price, PriceState>
{
  constructor(store: PriceStore) {
    super(store);
  }

  selectAggregated(): Observable<Price[]> {
    return this.combineAggregatedSources().pipe(
      mergeMap(() =>
        this.shouldAggregate()
          ? this.select('aggregated')
          : this.select('prices'),
      ),
      map((prices) => this.sortByCommodityGeographyScenario(prices)),
      map((prices) =>
        this.shouldFactorize()
          ? this.createFactorizer().factorize(prices)
          : prices,
      ),
    );
  }

  selectFactoredPrices(): Observable<Price[]> {
    return this.combineFactorsPricesAndFactorizing().pipe(
      mergeMap(() => this.select('prices')),
      map((prices) => this.sortByCommodityGeographyScenario(prices)),
      map((prices) =>
        this.shouldFactorize()
          ? this.createFactorizer().factorize(prices)
          : prices,
      ),
    );
  }

  getCommodities(): string[] {
    return GeneralUtils.removeDuplicates(
      this.getValue().prices.map((price) => price.commodity),
    );
  }

  selectLoadingSource(): Observable<boolean> {
    return this.select('loadingSource');
  }

  selectSourceInfo(): Observable<PriceSource> {
    return this.select((state) => state.source).pipe(filterNilValue());
  }

  selectCommodities(): Observable<string[]> {
    return this.select('prices').pipe(
      map((prices) => prices.map((price) => price.commodity)),
      map((commodities) => GeneralUtils.removeDuplicates(commodities)),
    );
  }

  selectActiveCommodity(): Observable<Commodity> {
    return this.select((state) => state.activeCommodity);
  }

  selectWasYearlyAggregated(): Observable<boolean> {
    return this.selectActiveCommodity().pipe(
      map((commodity) => this.wasYearlyAggregated(commodity)),
    );
  }

  private wasYearlyAggregated(commodity: Commodity): boolean {
    return [Commodity.ELECTRICITY].includes(commodity);
  }

  private combineAggregatedSources(): Observable<unknown> {
    return this.select([
      'factors',
      'factorize',
      'averageOn',
      'prices',
      'aggregated',
    ]).pipe(debounceTime(0));
  }

  private shouldAggregate(): boolean {
    return Coerce.array(this.getValue().averageOn).length > 0;
  }

  private createFactorizer(): PriceFactorizer {
    return new PriceFactorizer(this.buildFactorsAndFactorizerData());
  }

  private buildFactorsAndFactorizerData(): Factors & PriceFactorizerData {
    return { ...this.getValue().factors, years: this.getValue().years };
  }

  private shouldFactorize(): boolean {
    return this.getValue().factorize;
  }

  private combineFactorsPricesAndFactorizing(): Observable<unknown> {
    return this.select(['prices', 'factors', 'factorize']);
  }

  private sortByCommodityGeographyScenario(prices: Price[]): Price[] {
    return [...prices].sort(this.whichComesFirst.bind(this));
  }

  private whichComesFirst(previous: Price, next: Price): number {
    return this.buildSortKey(previous) < this.buildSortKey(next) ? -1 : 1;
  }

  private buildSortKey(from: Price): string {
    return `${from.commodity}_${from.geography}_${from.scenario}`;
  }
}
