<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="32px">
    <div data-test="tech" @first (click)="onTechClick()">
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'technologies' }"
      ></ng-container>
    </div>

    <div data-test="prices" @second (click)="onPricesClick()">
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'prices' }"
      ></ng-container>
    </div>
    <div data-test="factors" @third (click)="onFactorsClick()">
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'factors' }"
      ></ng-container>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="32px">
    <!-- <div class="unclickable" data-test="sourcing" @fourth>
      <div
        data-test="notYetDeveloped"
        fxLayout="row"
        fxLayoutAlign="center start"
        class="not-yet-developed"
      >
        {{ "landing.notYetDeveloped" | transloco | uppercase }}
      </div>
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'sourcing' }"
      ></ng-container>
    </div> -->
    <div data-test="sourcing" @fourth (click)="onSourcingClick()">
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'green-sourcing' }"
      ></ng-container>
    </div>
    <div data-test="weather" @fifth (click)="onWeatherClick()">
      <ng-container
        *ngTemplateOutlet="typeCard; context: { $implicit: 'weather' }"
      ></ng-container>
    </div>
    <div data-test="profiles" @sixth (click)="onProfileClick()">
      <ng-container
        *ngTemplateOutlet="
          typeCard;
          context: { $implicit: 'renewable-profiles' }
        "
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #typeCard let-name>
  <div
    class="data-types-card clickable"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
  >
    <mat-icon>{{ "landing.icon." + name | transloco }}</mat-icon>
    <span>{{ "landing.name." + name | transloco }}</span>
  </div>
</ng-template>

<ng-template #notYetDeveloped>
  <div
    data-test="notYetDeveloped"
    fxLayout="row"
    fxLayoutAlign="center start"
    class="not-yet-developed"
  >
    {{ "landing.notYetDeveloped" | transloco | uppercase }}
  </div>
</ng-template>
