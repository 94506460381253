import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coerce, GeneralUtils } from '@tdb/utils';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'tdb-price-load',
  templateUrl: './price-load.component.html',
  styleUrls: ['./price-load.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GeneralUtils.getCVAProvider(PriceLoadComponent)],
})
export class PriceLoadComponent extends ControlValueAccessor<number[]> {
  @Input() numberOfHours = 8760;
  readonly load = new FormControl<number[]>(this.initialLoadValue);

  constructor() {
    super();
    this.subscribeToLoadValueForCVAPropagation();
  }

  writeValue(value: number[]): void {
    this.load.patchValue(value);
  }

  onPasterClick(): void {
    this.selectClipboardDataAsNumbers()
      .pipe(take(1))
      .subscribe((data) => {
        this.load.patchValue(data);
      });
  }

  onPeyst(content: string): void {
    this.load.patchValue(this.coerceToNumbers(this.splitByNewLine(content)));
  }

  private subscribeToLoadValueForCVAPropagation(): void {
    this.load.value$
      .pipe(untilDestroyed(this))
      .subscribe((value) => Coerce.fn(this.onChange)(value));
  }

  private get initialLoadValue(): number[] {
    return Array(this.numberOfHours).fill(0);
  }

  private selectClipboardDataAsNumbers(): Observable<number[]> {
    return this.selectClipboardData().pipe(
      map((data) => this.splitByNewLine(data)),
      map((data) => this.coerceToNumbers(data)),
    );
  }

  private splitByNewLine(content: string): string[] {
    return content.split('\n');
  }

  private coerceToNumbers(from: string[]): number[] {
    return from.map((str) => Coerce.number(str));
  }

  private selectClipboardData(): Observable<string> {
    return from(navigator.clipboard.readText());
  }
}
