import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PriceLoadModule } from '@tdb/prices/components/price-load';
import { MultiInputsModule } from '../multi-inputs';
import { EggComponent } from './egg.component';

@NgModule({
  declarations: [EggComponent],
  imports: [CommonModule, MultiInputsModule, PriceLoadModule],
  exports: [EggComponent],
})
export class EggModule {}
