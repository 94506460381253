import { BehaviorSubject } from 'rxjs';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Coerce } from '@tdb/utils';

type Target = HTMLInputElement | DataTransfer;
const selector = 'tdb-file-upload';

@Component({
  selector,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor, Validator {
  @ViewChild('fileUpload', { read: ElementRef }) fileUpload:
    | ElementRef
    | undefined;
  @HostBinding('class') classList: string = selector;

  @Input() icon = 'attachment';
  @Input() hint: string | undefined;

  protected disabled$ = new BehaviorSubject<boolean>(false);
  @Input() set disabled(value: boolean) {
    this.disabled$.next(coerceBooleanProperty(value));
    this.changeDetector.markForCheck();
    this.updateClassList();
  }
  get disabled(): boolean {
    return this.disabled$.value;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') defaultClassList: string | null | undefined;

  formats = '*';
  @Input() set acceptedFormats(value: Array<string>) {
    if (!!value) {
      this.formats = value.join(',');
    }
  }

  file: File | undefined;
  dragover = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: File | undefined): void => {
    return;
  };
  onTouch = (): void => {
    return;
  };

  registerOnChange(fn: (_: File | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(file: File): void {
    this.file = file;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    return !this.file ? { invalid: true } : null;
  }

  onBrowse(): void {
    if ([!!this.fileUpload, !this.disabled].every(Boolean)) {
      Coerce.obj(this.fileUpload).nativeElement.click();
    }
  }

  onFileSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.applyValidChanges(target);
  }

  onDragOver(event: DragEvent): void {
    this.callDragEvents(event);
    this.dragover = true;
  }

  onDragLeave(event: DragEvent): void {
    this.callDragEvents(event);
    this.dragover = false;
  }

  onDrop(event: DragEvent): void {
    this.callDragEvents(event);
    this.dragover = false;

    this.applyValidChanges(event.dataTransfer);
  }

  protected isFileListValid(target: Target | null): boolean {
    const files = Array.from(this.getFileList(target)).filter((file) =>
      this.filterFileFormat(file),
    );
    return files.length > 0;
  }

  protected callDragEvents(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  protected applyValidChanges(target: Target | null): void {
    const isValid = this.isFileListValid(target);
    if (isValid) {
      this.file = this.getFileList(target)[0];
      this.onChange(this.file);
    } else {
      this.file = undefined;
      this.onChange(undefined);
    }
    this.onTouch();
  }

  protected getFileList(target: Target | null): FileList {
    const coercedTarget = Coerce.obj<Target>(target);
    return Coerce.obj<FileList>(coercedTarget.files);
  }

  protected updateClassList(): void {
    const defaultClass = Coerce.string(this.defaultClassList);
    if (this.disabled) {
      this.classList =
        `${defaultClass} ${selector} ${selector}-disabled`.trim();
    } else {
      this.classList = `${defaultClass} ${selector}`.trim();
    }
  }

  private filterFileFormat(file: File): boolean {
    return this.formats == '*' || this.formats.includes(file.type);
  }
}
