import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { SnackbarService } from '@tdb/services/snackbar';
import {
  CreateTicketResponse,
  SupportQuery,
  SupportStore,
  SupportTicket,
  TicketType,
} from '@tdb/support/state';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'tdb-jira-form',
  templateUrl: './jira-form.component.html',
  styleUrls: ['./jira-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JiraFormComponent {
  readonly ticketTypes = Object.values(TicketType);
  readonly ticketForm = this.builder.group<SupportTicket>(
    this.getInitialValues(),
  );
  readonly createAnother = new FormControl<boolean>(false);
  readonly submitting$: Observable<boolean>;

  // eslint-disable-next-line
  constructor(
    private builder: FormBuilder,
    private support: SupportStore,
    private dialogRef: MatDialogRef<JiraFormComponent>,
    private supportQuery: SupportQuery,
    private snacks: SnackbarService,
  ) {
    this.submitting$ = this.supportQuery.select('submitting');

    this.addRequiredValidatorsToRelevantControls();
  }

  onCreateTicket(): void {
    this.canCreateTicket()
      ? this.doCreateTicket()
      : this.markSummaryAndDescAsTouchedForErrors();
  }

  private markSummaryAndDescAsTouchedForErrors(): void {
    this.ticketForm.controls.summary.markAsTouched();
    this.ticketForm.controls.description.markAsTouched();
  }

  private doCreateTicket(): void {
    this.support
      .createTicket(this.ticketForm.value)
      .pipe(
        map((response) => response as CreateTicketResponse),
        take(1),
      )
      .subscribe({
        next: (rsp) => this.onSuccess(rsp.key),
        error: () => this.onFailure(),
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(): void {
    this.dialogRef.close();
  }

  private addRequiredValidatorsToRelevantControls(): void {
    // eslint-disable-next-line
    this.ticketForm.controls.summary.setValidators(Validators.required);
    // eslint-disable-next-line
    this.ticketForm.controls.description.setValidators(Validators.required);
  }

  private onSuccess(id: string): void {
    this.snacks.openOK(this.createSuccessMsg(id));
    this.shouldCreateAnother()
      ? this.ticketForm.reset(this.getInitialValues())
      : this.dialogRef.close();
  }

  private canCreateTicket(): boolean {
    return this.ticketForm.valid;
  }

  private onFailure(): void {
    this.snacks.openNOK(this.createFailureMsg());
  }

  private createSuccessMsg(id: string): string {
    return `You've created support ticket "${id}".`;
  }

  private createFailureMsg(): string {
    return 'Something went wrong while creating a support ticket';
  }

  private shouldCreateAnother(): boolean {
    return this.createAnother.value;
  }

  private getInitialValues(): SupportTicket {
    return { description: '', summary: '', type: this.ticketTypes[0] };
  }
}
