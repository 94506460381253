import { InjectionToken, Provider } from '@angular/core';
import { WebSocketConfig } from '@tdb/services/websocket/websocket.model';
import { AuthConfig } from '@oculus/auth';
import { environment } from './environment';

export interface EnvironmentConfig {
  readonly production: boolean;
  readonly cognito: { Auth: AuthConfig };
  readonly baseUrl: string;
  readonly websocket: WebSocketConfig;
  readonly endpoints: Endpoints;
  readonly mockApi?: boolean;
}

type Endpoints = { [key: string]: string };

export const ENDPOINTS: Endpoints = {
  // technology
  getCategories: 'technology/categories',
  getSubcategories: 'technology/categories/{categoryId}',
  getTechnologies: 'technology/categories/{categoryId}/{subcategoryId}',
  attributes: 'technology/categories/{cat}/{sub}/{tech}',
  // weather data
  submitWeatherProfile: 'meteomatics/submit', // restify? (verb)
  weatherProfiles: 'meteomatics/userRequests', // restify? (camel case)
  getWeatherProfile: 'meteomatics/{group}/{id}',
  downloadWeatherProfile: 'renewableprofile/{id}/download?status={status}', // for removal of static query param
  // generic
  sourceDataModules: 'admin/modules',
  sourceDataLogs: 'admin/log-file/download-url',
  sourceDataUpload: 'admin/modules/{module}/raw-data/upload-url',
  sourceDataUploadCategories: '{module}/categories',
  sourceData: '{module}',
  sourceDataFilter: '{module}/types',
  sourceDataAverage: '{module}/average',
  sourceDataItem: '{module}/types/{type}/items',
  genericExportRaw: '{module}/raw-data/download-url',
  genericSourceInfo: '{module}/types/{type}/source-info',
  genericFilterConfig: '{module}/types/{type}/configuration', // restify? (singular)
  // others & special cases
  priceFilteredMaster: 'prices/filtered-raw-data', // restify? (get request even does not need response)
  globalInfoDownload: 'global-info/download-url',
  globalInfoUpload: 'global-info/upload-url',
  markNotifsAsRead: 'notifications/read', // restify? (verb)
  notifs: 'notifications',
  createTicket: 'support',
  // exceptions: restify on TDB-1474
  genericExportXlsx: '{module}/types/{type}/excel', //technologies + sourcing (in progress)

  /** @deprecated [for standardization] The method should not be used  */
  sourceDataExport: '{module}/export',
  /** @deprecated [for standardization] The method should not be used  */
  exportPrices: 'prices/export',

  /** @deprecated [for standardization] The method should not be used  */
  priceMaster: 'prices/rawData',
  /** @deprecated [for standardization] The method should not be used  */
  exportFragment: 'export',
  /** @deprecated [for standardization] The method should not be used  */
  averagePrices: 'prices/average',
  /** @deprecated [for standardization] The method should not be used  */
  updateWeights: 'prices',
  /** @deprecated [for standardization] The method should not be used  */
  profileRawData: '{module}/types/{type}/downloadRawData',
};

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('tdb-env');

export function provideEnvironmentConfig(): Provider {
  return { provide: ENV_CONFIG, useValue: environment };
}
