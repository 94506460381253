import { Chance } from 'chance';
import Values from 'values.js';

import { ChartColor } from './chart.colors';

export class ChartUtils {
  private static readonly LEGEND_NAME_DELIMITER = '  I  ';

  static getRandomColor(): ChartColor {
    const colors = this.getColors();
    return colors[Math.floor(Math.random() * colors.length)];
  }

  static getAShade(base: ChartColor): string {
    const shades = this.getShades(base);
    return shades[this.getRandomShadeIndex(shades.length)];
  }

  static mapToLine(
    keys: string[],
    from: Record<string, string>,
    values: Record<string | number, number>,
  ): { name: string; points: number[] } {
    return {
      name: this.buildLegendName(keys, from),
      points: Object.values(values),
    };
  }

  static buildLegendName(keys: string[], from: Record<string, string>): string {
    return keys
      .map((key) => from[key])
      .filter(Boolean)
      .join(this.LEGEND_NAME_DELIMITER);
  }

  private static getRandomShadeIndex(length: number): number {
    return new Chance().integer({
      min: this.getThreshold(),
      max: length - this.getThreshold(),
    });
  }

  private static getThreshold(): number {
    return 3;
  }

  private static getShades(base: string): string[] {
    return new Values(base).all().map((value) => value.hexString());
  }

  private static getColors(): ChartColor[] {
    return Object.values(ChartColor);
  }
}
