import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveComponentModule } from '@ngrx/component';
import { FooterActionsModule } from '../footer-actions';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    MatMenuModule,
    FooterActionsModule,
    ReactiveComponentModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
