import { Commodity } from '@tdb/app/+prices/state';
import { DataType, EMPTY, NOT_APPLICABLE } from '@tdb/app/app.tokens';
import { UploadSourceDataRequest } from '@tdb/app/state/source-data';

export const SOURCING_APPEND =
  'Power Purchase Agreement - L10 - append new clients';

export function getInitValues(): UploadSourceDataRequest {
  return {
    module: NOT_APPLICABLE,
    type: NOT_APPLICABLE,
    source: NOT_APPLICABLE,
    nameOfClient: EMPTY,
    file: undefined,
  };
}

export function hasNoType(form: UploadSourceDataRequest): boolean {
  return getModulesWithoutType().includes(form.module);
}
export function hasNoSource(form: UploadSourceDataRequest): boolean {
  const accessor = getModuleTypeConcat(form.module, form.type);
  const moduleTypesWithoutSource = getModulesWithoutSource();
  return moduleTypesWithoutSource
    .map((moduleType) => accessor.includes(moduleType))
    .some(Boolean);
}
export function hasNameOfClient(form: UploadSourceDataRequest): boolean {
  return [
    DataType.SOURCING.toString() === form.module,
    SOURCING_APPEND === form.type,
  ].every(Boolean);
}

export function checkValue(value: string): boolean {
  return [!!String(value).trim(), value !== NOT_APPLICABLE].every(Boolean);
}

function getModuleTypeConcat(module: string, type: string | undefined): string {
  return [module, type].join('_');
}

function getModulesWithoutType(): string[] {
  return [DataType.FACTORS].map(String);
}

function getModulesWithoutSource(): string[] {
  return [
    getModuleTypeConcat(DataType.PRICES, Commodity.NON_ELECTRICITY),
    getModuleTypeConcat(DataType.FACTORS, undefined),
    getModuleTypeConcat(DataType.TECHNOLOGIES, undefined),
    getModuleTypeConcat(DataType.SOURCING, undefined),
  ];
}
