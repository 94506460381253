import { DEFAULT_COMMODITY } from './price.state';
import { Coerce } from '@tdb/app/utils';

export const FACTORIZE_DISABLED_COMMODITIES = ['Hydrogen'];

function coerceCommodity(criteria: Record<string, unknown>): string {
  return Coerce.string(criteria['commodity'], DEFAULT_COMMODITY);
}

function disableFactorize(commodity: string): boolean {
  return FACTORIZE_DISABLED_COMMODITIES.includes(commodity);
}

function isDefaultCommodity(commodity: string): boolean {
  return DEFAULT_COMMODITY === commodity;
}

export const PriceUtils = {
  coerceCommodity,
  disableFactorize,
  isDefaultCommodity,
};
