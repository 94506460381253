<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="notifs-title">
      {{ "labels.notifications" | transloco | titlecase }}
    </span>
    <button
      mat-icon-button
      color="primary"
      data-test="refresh"
      (click)="onRefresh()"
    >
      <mat-icon color="primary">refresh</mat-icon>
    </button>
  </div>
  <div class="notifs-divider"></div>
  <div
    *ngIf="loading$ | ngrxPush; else loaded"
    fxLayout="column"
    fxLayoutGap="8px"
  >
    <tdb-notif [loading]="true" *ngFor="let i of [0, 0]"></tdb-notif>
  </div>
  <ng-template #loaded>
    <div
      fxLayout="column"
      fxLayoutGap="8px"
      *ngIf="hasUnread$ | ngrxPush; else noNotifs"
    >
      <tdb-notif
        *ngFor="let notif of notifs$ | ngrxPush"
        [notif]="notif"
        [attr.data-test]="'notif-' + notif.id"
        class="clickable"
        (click)="onNotifClick(notif)"
      ></tdb-notif>
    </div>
    <ng-template #noNotifs>
      <div
        data-test="noNotifs"
        fxLayout="column"
        fxLayoutGap="16px"
        fxLayoutAlign="start center"
        class="no-notifs"
      >
        <mat-icon>upcoming</mat-icon>
        <span>{{ "messages.noNotifs" | transloco }}</span>
      </div>
    </ng-template>
  </ng-template>
</div>
