import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import {
  MAT_CHIPS_DEFAULT_OPTIONS,
  MatChipsDefaultOptions,
} from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import {
  MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@ng-matero/extensions/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { LetDirective, PushPipe } from '@ngrx/component';
import {
  AuthModule,
  AuthService as OculusAuthService,
} from '@oculus/auth/amplify';
import { ENV_CONFIG, environment } from '@tdb/environment';
import { MarkdownModule } from 'ngx-markdown';
import { GlobalInfoModule } from './+about/components/global-info';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EggModule } from './components/egg';
import { FrameModule } from './components/frame';
import { LandingPageModule } from './components/landing-page';
import { AuthService } from './core/auth/services/auth.service';
import { WS_CONFIG_TOKEN } from './services/websocket/websocket.tokens';
import { TranslocoRootModule } from './transloco';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LetDirective,
    PushPipe,
    BrowserAnimationsModule,
    MarkdownModule.forRoot(),
    FrameModule,
    LandingPageModule,
    EggModule,
    HttpClientModule,
    TranslocoRootModule,
    OverlayModule,
    MatSnackBarModule,
    [...(environment.production ? [] : [AkitaNgDevtools.forRoot()])],
    AkitaNgRouterStoreModule,
    NgSelectModule,
    GlobalInfoModule,
    MatNativeDateModule,
    /* Oculus Libraries */
    AuthModule.forRoot(environment.cognito.Auth),
    MatDialogModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: ENV_CONFIG, useValue: environment },
    { provide: WS_CONFIG_TOKEN, useValue: environment.websocket },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA, ENTER, SPACE],
      } as MatChipsDefaultOptions,
    },
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    MtxDialog,
    MatDialog,
    MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
    MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    { provide: OculusAuthService, useExisting: AuthService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
