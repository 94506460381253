import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataTypesModule } from '../data-types';
import { LandingPageComponent } from './landing-page.component';

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslocoModule,
    DataTypesModule,
  ],
  exports: [LandingPageComponent],
})
export class LandingPageModule {}
