import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationQuery } from '@tdb/notifs/state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tdb-notifs-trigger',
  templateUrl: './notifs-trigger.component.html',
  styleUrls: ['./notifs-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifsTriggerComponent {
  readonly count$ = this.selectUnreadCount();

  constructor(private readonly notifs: NotificationQuery) {}

  private selectUnreadCount(): Observable<string> {
    return this.notifs.selectUnread().pipe(
      map((notifs) => notifs.length),
      map((count) => this.transformZeroToEmptyIfApplicable(count)),
    );
  }

  private transformZeroToEmptyIfApplicable(count: number): string {
    return count === 0 ? '' : count.toString();
  }
}
