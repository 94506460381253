import { Injectable } from '@angular/core';
import { Query, QueryConfig } from '@datorama/akita';
import { AboutStore } from '.';
import { AboutState } from './about.state';

@Injectable({ providedIn: 'root' })
@QueryConfig({})
export class AboutQuery extends Query<AboutState> {
  constructor(store: AboutStore) {
    super(store);
  }
}
