import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { InfoModule } from '@tdb/app/+about/components/info';
import { NotifsTriggerModule } from '@tdb/notifs/components/notifs-trigger';
import { ActiveTypeModule } from '../active-type';

import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ActiveTypeModule,
    NotifsTriggerModule,
    MatIconModule,
    MatButtonModule,
    InfoModule,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
