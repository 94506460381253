<button
  mat-icon-button
  color="primary"
  data-test="notifsTrigger"
  [matMenuTriggerFor]="notifs"
  [matBadge]="count$ | ngrxPush"
  matBadgeColor="warn"
  matBadgeSize="small"
>
  <mat-icon color="primary">notifications</mat-icon>
</button>
<mat-menu #notifs="matMenu" xPosition="before" overlapTrigger="true">
  <tdb-notifs
    data-test="notifs"
    (click)="$event.stopPropagation()"
  ></tdb-notifs>
</mat-menu>
