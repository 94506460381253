import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Notification, NotificationState } from './notification.state';
import { NotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({ sortByOrder: Order.DESC, sortBy: 'date' })
export class NotificationQuery extends QueryEntity<NotificationState> {
  constructor(store: NotificationStore) {
    super(store);
  }

  selectUnread(): Observable<Notification[]> {
    return this.selectAll().pipe(map((all) => this.onlyUnread(all)));
  }

  private onlyUnread(from: Notification[]): Notification[] {
    return from.filter((n) => !n.read);
  }
}
