import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { DataType } from '@tdb/app/app.tokens';
import { Coerce } from '@tdb/utils';
import {
  fadeInLeftOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tdb-active-type',
  templateUrl: './active-type.component.html',
  styleUrls: ['./active-type.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation({ anchor: 'enter', duration: 300 }),
    fadeOutLeftOnLeaveAnimation({ anchor: 'leave', duration: 300 }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveTypeComponent {
  readonly urlHead$ = this.selectUrlHead();
  readonly isDataType$ = this.selectIsDataType();
  readonly types = this.getTypes();

  constructor(private route: RouterQuery, private router: Router) {}

  onClickOfType(type: DataType): void {
    void this.router.navigate([type]);
  }

  private selectIsDataType(): Observable<boolean> {
    return this.selectUrlHead().pipe(map((head) => this.isDataType(head)));
  }

  private selectUrlHead(): Observable<string> {
    return this.selectCurrentUrl().pipe(map((url) => this.extractHead(url)));
  }

  private selectCurrentUrl(): Observable<string> {
    return this.route.select('state').pipe(
      map((state) => Coerce.object(state).url),
      map((url) => Coerce.string(url)),
    );
  }

  private extractHead(from: string): DataType {
    return Coerce.string(from.split('/')[1]) as DataType;
  }

  private isDataType(alleged: unknown): boolean {
    return Object.values(DataType).includes(alleged as DataType);
  }

  private getTypes(): DataType[] {
    return Object.values(DataType);
  }
}
