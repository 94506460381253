<div fxLayout="column" fxLayoutAlign="start stretch">
  <div class="bg-engie-gradient" fxFlex="4px"></div>
  <nav
    data-test="navbar"
    class="nj-navbar nj-navbar--shadow nj-navbar--expand-xl"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <!-- <img
      style="display: none"
      class="nj-navbar__brand engie-logo"
      src="/assets/engie/logo-engie-blue.svg"
      data-test="logo"
      (click)="onHome()"
    /> -->
    <img
      class="nj-navbar__brand app-logo"
      src="/assets/logos/tdb_logo.svg"
      data-test="app-logo"
      (click)="onHome()"
    />
    <div
      class="nj-navbar--collapse nj-collapse"
      id="navbarExampleSmall"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="16px"
    >
      <tdb-active-type *ngIf="loggedIn"></tdb-active-type>
      <tdb-info *ngIf="loggedIn"></tdb-info>
      <tdb-notifs-trigger *ngIf="loggedIn"></tdb-notifs-trigger>
      <button
        *ngIf="!loggedIn; else logout"
        mat-flat-button
        color="primary"
        data-test="login"
        (click)="onLogin()"
      >
        LOGIN
      </button>
      <ng-template #logout>
        <button mat-button data-test="logout" (click)="onLogout()">
          <mat-icon color="primary">logout</mat-icon>
        </button>
      </ng-template>
    </div>
  </nav>
</div>
