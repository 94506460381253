import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NotificationType,
  SimpleNotification,
} from '@tdb/app/notifications/state';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tdb-notif',
  templateUrl: './notif.component.html',
  styleUrls: ['./notif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifComponent {
  private readonly notifCache = new BehaviorSubject<SimpleNotification>(
    {} as SimpleNotification,
  );
  @Input() set notif(value: SimpleNotification) {
    this.notifCache.next(value);
  }
  @Input() loading = false;

  readonly notif$ = this.notifCache.asObservable();
  readonly typeIconBag = this.getTypeIconBag();

  private getTypeIconBag(): Record<NotificationType, string> {
    return {
      [NotificationType.EXPORT_FINISHED]: 'cloud_download',
      [NotificationType.EXPORT_FAILED]: 'error_outline',
      [NotificationType.UPLOAD_PENDING]: 'pending',
      [NotificationType.UPLOAD_PROCESSING]: 'cloud_sync',
      [NotificationType.UPLOAD_FINISHED]: 'cloud_done',
      [NotificationType.UPLOAD_FAILED]: 'assignment_late',
    };
  }
}
