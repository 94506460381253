import {
  zoomInOnEnterAnimation,
  rotateInOnEnterAnimation,
} from 'angular-animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UploadSourceDataComponent } from '../upload-source-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionCheckerService } from '@tdb/services/permission-checker';
import { UploadFormComponent } from '@tdb/app/+about/components/upload-form';
import { JiraFormComponent } from '@tdb/support/components/jira-form';
import { AnimaUtils } from '@tdb/utils';
import { startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { SourceDataQuery } from '@tdb/app/state/source-data';

@UntilDestroy()
@Component({
  selector: 'tdb-footer-actions',
  templateUrl: './footer-actions.component.html',
  styleUrls: ['./footer-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    zoomInOnEnterAnimation(AnimaUtils.createAnimationOptions('1in')),
    zoomInOnEnterAnimation(AnimaUtils.createAnimationOptions('2in', 100)),
    rotateInOnEnterAnimation(AnimaUtils.createAnimationOptions('close')),
  ],
})
export class FooterActionsComponent {
  private readonly mouseEnter = new BehaviorSubject<boolean>(false);
  readonly mouseEnter$ = this.mouseEnter.asObservable();
  readonly uploadAccess$: Observable<boolean>;
  readonly adminAccess$: Observable<boolean>;

  @Input() adminAccess = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly sourceDataQuery: SourceDataQuery,
    private readonly checker: PermissionCheckerService,
  ) {
    this.uploadAccess$ = this.getUploadAccess();
    this.adminAccess$ = this.getAdminAccess();
  }

  onMouseEnter(): void {
    this.mouseEnter.next(true);
  }

  onMouseLeave(): void {
    this.mouseEnter.next(false);
  }

  onCreateTicket(): void {
    this.dialog.open(JiraFormComponent, this.getSupportTicketDialogConfig());
  }

  onUploadInfo(): void {
    this.dialog.open(UploadFormComponent, this.getUploadInfoDialogConfig());
  }

  onUploadData(): void {
    this.dialog.open(
      UploadSourceDataComponent,
      this.getUploadDataDialogConfig(),
    );
  }

  private getUploadAccess(): Observable<boolean> {
    return this.sourceDataQuery
      .selectHasDataModules()
      .pipe(untilDestroyed(this));
  }

  private getAdminAccess(): Observable<boolean> {
    return this.checker.adminAccess$.pipe(
      untilDestroyed(this),
      startWith(false),
    );
  }

  private getSupportTicketDialogConfig(): MatDialogConfig {
    return { disableClose: true, width: '500px' };
  }

  private getUploadInfoDialogConfig(): MatDialogConfig {
    return { disableClose: true, width: '500px' };
  }

  private getUploadDataDialogConfig(): MatDialogConfig {
    return { disableClose: true, width: '500px' };
  }
}
