import {
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
} from '@angular/core';
import { Coerce } from '@tdb/utils';

@Directive({
  selector: '[tdbPaste]',
})
export class PasteDirective {
  @Output() peyst = new EventEmitter<string>();

  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.listenToPasteForEmission();
  }

  private listenToPasteForEmission(): void {
    this.renderer.listen(
      this.getNativeElement(),
      'paste',
      (event: ClipboardEvent) => this.peyst.emit(this.getTextContent(event)),
    );
  }

  private getTextContent(from: ClipboardEvent): string {
    return this.coerceClipboardData(from).getData('text');
  }

  private coerceClipboardData(event: ClipboardEvent): DataTransfer {
    return Coerce.object(event.clipboardData) as unknown as DataTransfer;
  }

  private getNativeElement(): HTMLElement {
    return this.element.nativeElement;
  }
}
