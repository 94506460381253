import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { Observable, Observer } from 'rxjs';
import { finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  AboutState,
  DownloadGlobalInfoResponse,
  UploadGlobalInfoResponse,
} from './about.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'about' })
export class AboutStore extends Store<AboutState> {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointProviderExt,
  ) {
    super({ uploadingInfo: false, loadingInfo: false, infoContent: '' });
  }

  uploadGlobalInfo(file: File): Observable<unknown> {
    this.update({ uploadingInfo: true });
    const params = { filename: file.name };
    return this.http
      .get<UploadGlobalInfoResponse>(this.endpoints.forGlobalInfoUpload(), {
        params,
      })
      .pipe(
        mergeMap((response) => this.uploadFile(response.signed_url, file)),
        finalize(() => this.update({ uploadingInfo: false, infoContent: '' })),
      );
  }

  downloadGlobalInfo(): Observable<unknown> {
    this.update({ loadingInfo: true });
    return this.http
      .get<DownloadGlobalInfoResponse>(this.endpoints.forGlobalInfoDownload())
      .pipe(
        mergeMap((response) =>
          this.processGlobalInfoContent(response.signed_url),
        ),
      );
  }

  private processGlobalInfoContent(signedUrl: string): Observable<unknown> {
    return this.http.get(signedUrl, { responseType: 'blob' }).pipe(
      switchMap(
        (data) =>
          new Observable<string>((observer: Observer<string>) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              observer.next(reader.result as string);
              observer.complete();
            };
            reader.readAsText(data);
          }),
      ),
      map((data) => this.update({ loadingInfo: false, infoContent: data })),
    );
  }

  private uploadFile(signedUrl: string, file: File) {
    return this.http.put(signedUrl, file);
  }
}
