import { Inject, Injectable } from '@angular/core';
import {
  AUTH_CONFIG_TOKEN,
  AuthConfig,
  AuthState,
  AuthService as OculusAuthService,
} from '@oculus/auth/amplify';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TDB_OKTA_GROUP, TDB_OKTA_GROUPS } from '../auth.tokens';

export type UserAttributes = { attributes: Record<string, string> } | undefined;

@Injectable({ providedIn: 'root' })
export class AuthService extends OculusAuthService {
  adminAccess$ = this.state$.pipe(
    map((state: AuthState) =>
      this.hasSpecificPermission(
        state.user as UserAttributes,
        TDB_OKTA_GROUP.ADMIN,
      ),
    ),
  );

  constructor(@Inject(AUTH_CONFIG_TOKEN) readonly authConfig: AuthConfig) {
    super(authConfig);
    this.user$ = this.state$.pipe(
      filter((state) => this.filterLoggedUser(state)),
      map((state) => state.user as UserAttributes),
      distinctUntilChanged(),
    );
  }

  hasSomePermission(permissions: TDB_OKTA_GROUPS[]): Observable<boolean> {
    return this.state$.pipe(
      map((state: AuthState) =>
        permissions.some((value) =>
          this.hasSpecificPermission(state.user as UserAttributes, value),
        ),
      ),
    );
  }

  private filterLoggedUser({ loggedIn, user }: AuthState): boolean {
    return [loggedIn, !!user].every(Boolean);
  }

  private hasSpecificPermission(user: UserAttributes, value: string): boolean {
    return this.getGroups(user).indexOf(value) !== -1;
  }

  private getGroups(user: UserAttributes): string {
    if (!!user) {
      return user.attributes['custom:groupName'];
    }
    return '[]';
  }
}
