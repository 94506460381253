import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { EndpointProviderExt } from '@tdb/services/endpoint-provider';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { NotifMapper } from './notification.mapper';
import {
  BENotif,
  NotificationState,
  SimpleNotification,
} from './notification.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'notif' })
export class NotificationStore extends EntityStore<NotificationState> {
  // eslint-disable-next-line
  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProviderExt,
  ) {
    super();
  }

  getAll(): Observable<unknown> {
    this.setLoading(true);
    return this.http.get<BENotif[]>(this.endpoints.forNotifs()).pipe(
      map((rsp) => this.toSimpleNotifs(rsp)),
      tap((notifs) => this.set(notifs)),
      finalize(() => this.setLoading(false)),
    );
  }

  markAsRead(id: string): Observable<unknown> {
    return this.http
      .post(this.endpoints.forMarkNotifsAsRead(), [id])
      .pipe(tap(() => this.markNotifAsReadInState(id)));
  }

  private markNotifAsReadInState(id: string): void {
    this.update(id, { read: true });
  }

  private toSimpleNotifs(froms: BENotif[]): SimpleNotification[] {
    return froms.map((from) => NotifMapper.toSimpleNotif(from));
  }
}
