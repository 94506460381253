import { Coerce } from '@tdb/utils';
import { Factors, Price } from './price.state';

export interface PriceFactorizerData {
  readonly years: number[];
}

export class PriceFactorizer {
  private readonly factors: Factors;
  private readonly indexationBag: Record<number, number>;

  constructor(data: Partial<Factors> & Partial<PriceFactorizerData>) {
    this.factors = data as Factors;
    this.indexationBag = this.buildIndexationBag(Coerce.array(data.years));
  }

  factorize(prices: Price[]): Price[] {
    return prices.map((price) => this.factorizePrice(price));
  }

  private factorizePrice(price: Price): Price {
    return { ...price, values: this.factorizeValues(price.values) };
  }

  private factorizeValues(values: Values): Values {
    const entries = Object.entries(values).reduce((acc, [year, value]) => {
      acc = { ...acc, [year]: this.factorizeValue(value, Number(year)) };
      return acc;
    }, {});
    return entries;
  }

  private factorizeValue(value: number, year: number): number {
    let factorized = value;
    factorized = this.factorMargin(factorized);
    factorized = this.factorTeeDeeAndIndexation(factorized, year);

    factorized = this.factorTaxes(factorized);
    return factorized;
  }

  private factorTeeDeeAndIndexation(value: number, year: number): number {
    return (
      Number(value) + this.factors.transpoAndDistrib * this.getIndexation(year)
    );
  }

  private getIndexation(year: number): number {
    return Coerce.number(this.indexationBag[year], 1);
  }

  private factorMargin(value: number): number {
    return Number(value) * this.getMarginalizedSector();
  }

  private factorTaxes(value: number): number {
    return Number(value) * this.getFakeTaxis();
  }

  private getMarginalizedSector(): number {
    return 1 + Coerce.number(this.factors.margin);
  }

  private getFakeTaxis(): number {
    return 1 + Coerce.number(this.factors.taxes);
  }

  private buildIndexationBag(years: number[]): Record<number, number> {
    const sorted = [...years].sort();
    return sorted.reduce<Record<number, number>>((acc, year) => {
      acc[year] = this.computeIndexation(year, sorted[0]);
      return acc;
    }, {});
  }

  private computeIndexation(currentYear: number, startYear: number): number {
    return Math.pow(
      this.preIndexation(),
      this.getYearGap(startYear, currentYear),
    );
  }

  private getYearGap(from: number, to: number): number {
    return to - from;
  }

  private preIndexation(): number {
    return 1 + Coerce.number(this.factors.indexation);
  }
}

type Values = Record<number, number>;
