import { forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { from, Observable } from 'rxjs';
import selectFiles, { Options } from 'select-files';
import { Coerce } from './coercion.utils';

export const GeneralUtils = {
  removeDuplicates,
  isNonEmptyArray,
  isWithin,
  isValidNonEmptyObject,
  getCVAProvider,
  browseFiles,
  matchesAnyOf,
};

function removeDuplicates<T>(from: T[]): T[] {
  return Array.from(new Set(from));
}

function isNonEmptyArray<T>(alleged: T[] | undefined | null): boolean {
  return !!(alleged || []).length;
}

function isWithin(
  thisNumber: number,
  within: [number | undefined, number | undefined],
): boolean {
  return (
    Coerce.number(within[0], Number.MIN_SAFE_INTEGER) <= thisNumber &&
    thisNumber <= Coerce.number(within[1], Number.MAX_SAFE_INTEGER)
  );
}

function isValidNonEmptyObject(alleged: unknown): boolean {
  // eslint-disable-next-line
  return !!alleged && !!Object.keys(alleged as any).length;
}

function getCVAProvider(type: unknown): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(
      /* istanbul ignore next */
      () => type,
    ),
    multi: true,
  };
}

function browseFiles(options: Options): Observable<FileList> {
  return from(selectFiles(options)) as Observable<FileList>;
}

function matchesAnyOf<T extends Partial<unknown>>(
  value: string,
  from: T,
): boolean {
  return Object.values(from).some((v) => insensitiveIncludes(value, String(v)));
}

function insensitiveIncludes(value: string, from: string): boolean {
  return from.toLowerCase().includes(value.toLowerCase());
}
