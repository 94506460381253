import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EndpointProviderService } from '@tdb/services/endpoint-provider';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CreateTicketRequest } from '.';
import { SupportState, SupportTicket } from './support.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'support' })
export class SupportStore extends Store<SupportState> {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointProviderService,
  ) {
    super({ submitting: false });
  }

  createTicket(ticket: SupportTicket): Observable<unknown> {
    this.update({ submitting: true });
    return this.http
      .post(
        this.endpoints.forCreateTicket(),
        this.toCreateTicketRequest(ticket),
      )
      .pipe(finalize(() => this.update({ submitting: false })));
  }

  private toCreateTicketRequest(from: SupportTicket): CreateTicketRequest {
    return { fields: { ...from, issuetype: from.type } };
  }
}
