import { DataType } from '@tdb/app/app.tokens';

export type TDB_OKTA_GROUP_TYPE = `${
  | TDB_OKTA_GROUP_COMMODITY_UPLOADER
  | TDB_OKTA_GROUP_EMMISSION_FACTOR_UPLOADER
  | TDB_OKTA_GROUP_TECHNOLOGY_UPLOADER
  | TDB_OKTA_GROUP}`;

export enum TDB_OKTA_GROUP {
  ADMIN = 'transversaldatabase_admin',
  UPLOADER_COMMODITY = 'TDB_commodity',
  UPLOADER_EMISSION_FACTOR = 'TDB_emission_factor',
  UPLOADER_TECHNOLOGY = 'TDB_technology',
}

export enum TDB_OKTA_GROUP_COMMODITY_UPLOADER {
  COMMODITY_ELECTRICITY = 'TDB_commodity_electricity',
  COMMODITY_BIOMETHANE = 'TDB_commodity_biomethane',
  COMMODITY_CO2_EU_ETS = 'TDB_commodity_co2_eu_ets',
  COMMODITY_CO2_OFFSET = 'TDB_commodity_co2_offset',
  COMMODITY_COAL = 'TDB_commodity_coal',
  COMMODITY_NATURAL_GAS = 'TDB_commodity_gas',
}

export enum TDB_OKTA_GROUP_EMMISSION_FACTOR_UPLOADER {
  EMISSION_EMISSION_GRID_FACTOR_PRODUCTION_BASED = 'TDB_emission_factor_grid',
  EMISSION_NON_ELECTRICITY_EMISSION_FACTOR = 'TDB_emission_factor_other',
}

export enum TDB_OKTA_GROUP_TECHNOLOGY_UPLOADER {
  TECHNOLOGY_CONVERTER = 'TDB_technology_converter',
  TECHNOLOGY_GENERATOR = 'TDB_technology_generator',
  TECHNOLOGY_RENEWABLE_GENERATOR = 'TDB_technology_renewable_generator',
  TECHNOLOGY_STORAGE = 'TDB_technology_storage',
}

export type UploadPermissionMapType = {
  [module: string]: TDB_OKTA_GROUP_TYPE[];
};

export const getUploadPermissionMap = (): UploadPermissionMapType => ({
  [DataType.PRICES]: [
    ...Object.values(TDB_OKTA_GROUP_COMMODITY_UPLOADER),
    TDB_OKTA_GROUP.UPLOADER_COMMODITY,
  ],
  [DataType.FACTORS]: [
    ...Object.values(TDB_OKTA_GROUP_EMMISSION_FACTOR_UPLOADER),
    TDB_OKTA_GROUP.UPLOADER_EMISSION_FACTOR,
  ],
  [DataType.TECHNOLOGIES]: [
    ...Object.values(TDB_OKTA_GROUP_TECHNOLOGY_UPLOADER),
    TDB_OKTA_GROUP.UPLOADER_TECHNOLOGY,
  ],
});
