import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataTypesComponent } from './data-types.component';

@NgModule({
  declarations: [DataTypesComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, TranslocoModule],
  exports: [DataTypesComponent],
})
export class DataTypesModule {}
