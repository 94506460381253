import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ALLOWED_DATA_FILE_EXT, NOT_APPLICABLE } from '@tdb/app/app.tokens';
import {
  SourceDataQuery,
  SourceDataStore,
  UploadSourceDataRequest,
  UploadSourceDataResponse,
} from '@tdb/app/state/source-data';
import { SnackbarService } from '@tdb/services/snackbar';

import {
  getInitValues,
  hasNameOfClient,
  hasNoSource,
  hasNoType,
} from './upload-source-data.ext';

type DisablerFn = (value: UploadSourceDataRequest) => boolean;

@UntilDestroy()
@Component({
  selector: 'tdb-upload-source-data',
  templateUrl: './upload-source-data.component.html',
  styleUrls: ['./upload-source-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadSourceDataComponent implements OnInit {
  readonly showNameOfClient = new BehaviorSubject(false);
  readonly allowedFileExtns = ALLOWED_DATA_FILE_EXT;
  readonly defaultValue = NOT_APPLICABLE;

  readonly modules$: Observable<string[]>;
  readonly subTypes$: Observable<string[]>;
  readonly hasNameOfClient$: Observable<boolean>;
  readonly loadingModule$: Observable<boolean>;
  readonly loadingType$: Observable<boolean>;
  readonly loadingSource$: Observable<boolean>;
  readonly uploadingData$: Observable<boolean>;
  readonly uploadForm = this.builder.group<UploadSourceDataRequest>(
    getInitValues(),
  );

  // eslint-disable-next-line max-params
  constructor(
    private dialogRef: MatDialogRef<UploadSourceDataComponent>,
    private sourceDataStore: SourceDataStore,
    private sourceDataQuery: SourceDataQuery,
    private snacks: SnackbarService,
    private builder: FormBuilder,
  ) {
    this.uploadingData$ = this.sourceDataQuery.selectUploadingData();
    this.loadingModule$ = this.sourceDataQuery.selectLoadingModules();
    this.loadingType$ = this.sourceDataQuery.selectLoadingTypes();
    this.loadingSource$ = this.sourceDataQuery.selectLoadingSource();
    this.hasNameOfClient$ = this.showNameOfClient.asObservable();
    this.modules$ = this.sourceDataQuery.selectDataModules();
    this.subTypes$ = this.sourceDataQuery.selectDataTypes();
  }

  ngOnInit(): void {
    this.subscribeInitModules();
    this.addSourceDataAuthorValidation();
    this.disableFields();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(): void {
    this.dialogRef.close();
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  onUploadSourceData(): void {
    if (!this.blockSubmit()) {
      const request = this.uploadForm.getRawValue();
      this.sourceDataStore
        .uploadSourceData(request)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => this.onSuccess(),
          error: ({ error }: { error: UploadSourceDataResponse }) =>
            this.onFailure(error),
        });
    }
  }

  loadModuleAssociations(type: string): void {
    this.showNameOfClient.next(hasNameOfClient(this.uploadForm.value));
    this.requestTypeFilters(type);
  }
  loadTypeSource(type: string): void {
    this.showNameOfClient.next(hasNameOfClient(this.uploadForm.value));
    this.requestTypeSource(type);
  }

  blockSubmit(): boolean {
    return [this.sourceDataQuery.getLoading(), !this.uploadForm.valid].some(
      Boolean,
    );
  }

  private requestTypeFilters(type: string): void {
    this.uploadForm.patchValue({
      source: this.defaultValue,
      type: this.defaultValue,
    });
    this.sourceDataStore
      .requestForTypes(type)
      .pipe(untilDestroyed(this))
      .subscribe(([reqType]) => {
        this.initTypeFilters(reqType);
        this.requestTypeSource(reqType);
      });
  }

  private requestTypeSource(type: string): void {
    this.uploadForm.patchValue({ source: this.defaultValue });
    if (!hasNoSource(this.uploadForm.value)) {
      this.sourceDataStore
        .requestForSource(this.uploadForm.value.module, type)
        .pipe(untilDestroyed(this))
        .subscribe(({ source }) => this.initSource(source));
    } else {
      this.sourceDataStore.triggerLoadingDataType();
    }
  }

  private disableFields(): void {
    this.uploadForm.controls.module.disabledWhile(this.loadingModule$);
    this.uploadForm.controls.type.disabledWhile(
      this.mapFnToObservable(this.loadingType$, hasNoType),
    );
    this.uploadForm.controls.source.disabledWhile(
      this.mapFnToObservable(this.loadingSource$, hasNoSource),
    );
  }

  private mapFnToObservable(
    loading$: Observable<boolean>,
    fn: DisablerFn,
  ): Observable<boolean> {
    return loading$.pipe(
      untilDestroyed(this),
      map((disableState) =>
        [disableState, fn(this.uploadForm.value)].some(Boolean),
      ),
    );
  }

  private initTypeFilters(filter: string): void {
    this.uploadForm.controls.type.setValue(filter);
  }
  private initSource(source: string): void {
    this.uploadForm.controls.source.setValue(source);
  }
  private subscribeInitModules(): void {
    this.modules$.subscribe(([module]) => {
      this.uploadForm.patchValue({ module });
      this.loadModuleAssociations(module);
    });
  }

  private addSourceDataAuthorValidation(): void {
    this.uploadForm.controls.source.setValidators((ctrl) =>
      Validators.required(ctrl),
    );
  }

  private onSuccess(): void {
    this.snacks.openOK(this.createSuccessMsg());
    this.dialogRef.close();
  }
  private onFailure(response: UploadSourceDataResponse): void {
    this.snacks.openNOK(response.errorMessage);
  }
  private createSuccessMsg(): string {
    return `Successfully uploaded source data file`;
  }
}
