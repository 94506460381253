import { Inject, Injectable } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from '@tdb/environment';
import { EndpointProviderService } from './endpoint-provider.service';
import template from 'url-template';

@Injectable({ providedIn: 'root' })
export class EndpointProviderExt extends EndpointProviderService {
  constructor(
    @Inject(ENV_CONFIG) protected readonly environment: EnvironmentConfig,
  ) {
    super(environment);
  }

  forWeatherProfiles(): string {
    return this.getUrlTemplate('weatherProfiles');
  }

  forSubmitWeatherProfile(): string {
    return this.getUrlTemplate('submitWeatherProfile');
  }

  forWeatherProfile(group: string, id: string): string {
    return template.parse(this.getUrlTemplate('getWeatherProfile')).expand({
      group,
      id,
    });
  }

  forDownloadWeather(id: string, status: string): string {
    return template
      .parse(this.getUrlTemplate('downloadWeatherProfile'))
      .expand({ id, status });
  }

  forNotifs(): string {
    return this.getUrlTemplate('notifs');
  }

  forFilteredPriceSource(): string {
    return this.getUrlTemplate('priceFilteredMaster');
  }

  forMarkNotifsAsRead(): string {
    return this.getUrlTemplate('markNotifsAsRead');
  }

  forGlobalInfoUpload(): string {
    return this.getUrlTemplate('globalInfoUpload');
  }

  forGlobalInfoDownload(): string {
    return this.getUrlTemplate('globalInfoDownload');
  }

  forSourceDataLogs(): string {
    return this.getUrlTemplate('sourceDataLogs');
  }

  forSourceDataModules(): string {
    return this.getUrlTemplate('sourceDataModules');
  }

  forSourceDataUpload(module: string): string {
    return template.parse(this.getUrlTemplate('sourceDataUpload')).expand({
      module,
    });
  }

  forSourceDataUploadCategories(module: string): string {
    return template
      .parse(this.getUrlTemplate('sourceDataUploadCategories'))
      .expand({ module });
  }

  forSourceDataFilter(module: string): string {
    return template.parse(this.getUrlTemplate('sourceDataFilter')).expand({
      module,
    });
  }

  forAverageData(module: string): string {
    return template.parse(this.getUrlTemplate('sourceDataAverage')).expand({
      module,
    });
  }

  forSourceData(module: string): string {
    return template.parse(this.getUrlTemplate('sourceData')).expand({
      module,
    });
  }

  forSourceDataItem(module: string, type: string): string {
    return template.parse(this.getUrlTemplate('sourceDataItem')).expand({
      module,
      type,
    });
  }

  forFiltrationConfig(module: string, type: string): string {
    return template
      .parse(this.getUrlTemplate('genericFilterConfig'))
      .expand({ module, type });
  }

  forSourceDataInfo(module: string, type: string): string {
    return template.parse(this.getUrlTemplate('genericSourceInfo')).expand({
      module,
      type,
    });
  }

  forSourceDataExportXlsx(module: string, type: string): string {
    return template.parse(this.getUrlTemplate('genericExportXlsx')).expand({
      module,
      type,
    });
  }

  forSourceDataExportRaw(module: string): string {
    return template.parse(this.getUrlTemplate('genericExportRaw')).expand({
      module,
    });
  }

  /** @deprecated [for standardization] The method should not be used  */
  forProfileDataExportRaw(module: string, type: string): string {
    return template.parse(this.getUrlTemplate('profileRawData')).expand({
      module,
      type,
    });
  }

  /** @deprecated [for standardization] The method should not be used  */
  forSourceDataExport(module: string): string {
    return template.parse(this.getUrlTemplate('sourceDataExport')).expand({
      module,
    });
  }
}
